import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery, } from "@mui/material";
import { Add, DeleteForever, FilterAltOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { deleteBoardAsync, enableDisableBoardAsync, getBoardsAsync, getEffectedBoardAsync, } from "@application/features/board/boardThunk";
import "./style.scss";
import WNSwitch from "@ui/components/wn-switch";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import WNLongMenu from "@ui/components/wn-long-menu";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { setBoardFilterModel, setDeleteOpenDialog, setEffectedBoard, } from "@application/features/board/boardSlice";
import { resetFilter } from "@application/features/common/filterResetSlice";
import AlertDialog from "@ui/components/wn-dialog";
function Board() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const { boards, boardFilterModel, effectedRows, isDeleteOpen } = useAppSelector((state) => state.board);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const [deletedUserId, setDeletedUserId] = useState();
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Boards]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const hasDeletePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.deletePermission]]
        : false;
    useEffect(() => {
        dispatch(getBoardsAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(resetFilter("boards"));
    }, [dispatch]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableBoardAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getBoardsAsync());
            }
        });
    };
    const columns = [
        {
            field: "name",
            headerName: translate("boards.boardName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "totalSchools",
            headerName: translate("schools.schools").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "totalAgreements",
            headerName: translate("agreements.agreements").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 190 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "lastAgreementDate",
            headerName: translate("boards.lastAgreement").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 250 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.lastAgreementDate !== null
                ? formateDate(params.row.lastAgreementDate, DateFormatConstants.DMY)
                : ""}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            filterable: false,
            hideSortIcons: false,
            hideable: false,
            flex: 1,
            minWidth: isSmall ? 130 : undefined,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(_Fragment, { children: params.row.name == "Uncategorized" ? (_jsx(_Fragment, { children: _jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => { }, disable: true }, params.row.id) })) : (_jsx(_Fragment, { children: _jsx(WNSwitch, { checked: params.row.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id) })) })),
        },
        {
            field: "deleteUser",
            headerName: translate("account.delete").toUpperCase(),
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(_Fragment, { children: params.row.name == "Uncategorized" ? (_jsx(_Fragment, {})) : (_jsx(Tooltip, { title: translate("account.delete"), arrow: true, placement: "top", children: _jsx(IconButton, { size: "medium", color: "error", onClick: () => handleDeleteOpenDialog(params.row), children: _jsx(DeleteForever, {}) }) })) })),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            filterable: false,
            hideSortIcons: false,
            hideable: false,
            renderCell: (params) => (_jsx(_Fragment, { children: _jsx(WNLongMenu, { options: getOptions(params.row), onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id) })),
        },
    ];
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "ViewSchools":
                navigate(`/admin/boards/${row.id}/schools`);
                break;
            default:
                break;
        }
    };
    const getOptions = (row) => {
        const options = [
            ...(hasViewPermission || hasEditPermission
                ? [
                    {
                        key: "Details",
                        label: translate("cta.details"),
                        route: "Details",
                        isActive: row.name === "Uncategorized" ? false : true,
                    },
                ]
                : []),
            ...(hasViewPermission
                ? [
                    {
                        key: "ViewSchools",
                        label: translate("cta.viewSchools"),
                        route: "ViewSchools",
                        isActive: true,
                    },
                ]
                : []),
        ];
        return options;
    };
    const onFilterModelChange = (model) => dispatch(setBoardFilterModel(model));
    const onClearFilterModel = () => dispatch(setBoardFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    const handleConfirmDeleteAction = async () => {
        if (deletedUserId) {
            const result = await dispatch(deleteBoardAsync(deletedUserId));
            if (result.payload === true) {
                dispatch(getBoardsAsync());
            }
        }
        handleDeleteCloseDialog();
    };
    const handleDeleteOpenDialog = (params) => {
        setDeletedUserId(params.id);
    };
    const handleDeleteCloseDialog = () => {
        setDeletedUserId("");
        dispatch(setEffectedBoard(undefined));
        dispatch(setDeleteOpenDialog(false));
    };
    useEffect(() => {
        if (deletedUserId && deletedUserId !== "") {
            dispatch(getEffectedBoardAsync(deletedUserId));
        }
    }, [dispatch, deletedUserId]);
    useEffect(() => {
        if (effectedRows) {
            dispatch(setDeleteOpenDialog(true));
        }
    }, [dispatch, effectedRows]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("boards.boardsOfSchools") }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })), hasCreatePermission &&
                                (isxs ? (_jsx(IconButton, { size: "medium", onClick: () => navigate("/admin/boards/add"), children: _jsx(Add, { color: "primary", fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/boards/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") })))] })] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { componentsProps: { filterPanel: { sx: { maxWidth: "85vw" } } }, autoHeight: true, rows: boards ?? [], columns: columns, filterModel: boardFilterModel, onFilterModelChange: onFilterModelChange, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !boards?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasEditPermission || hasViewPermission) ?? true,
                            deleteUser: hasDeletePermission === true ? true : false
                        } }, "board-grid") }) }), _jsx(AlertDialog, { open: isDeleteOpen, onClose: handleDeleteCloseDialog, title: translate("deleteUserDialog.title"), content: translate("deleteUserDialog.content"), onConfirm: handleConfirmDeleteAction, onCancel: handleDeleteCloseDialog, confirmButtonText: translate("cta.proceed"), cancelButtonText: translate("cta.cancel"), isDeleted: true, description: _jsxs("div", { children: [_jsx("p", { children: translate("deleteUserDialog.followingData") }), effectedRows && effectedRows?.totalSchools > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.totalSchools"), " =", " ", effectedRows?.totalSchools] })), effectedRows && effectedRows?.totalUsers > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.totalUsers"), " =", " ", effectedRows?.totalUsers] })), effectedRows && effectedRows?.totalCourses > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherCourses"), " =", " ", effectedRows?.totalCourses] })), effectedRows && effectedRows?.totalThemes > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherThemes"), " =", " ", effectedRows?.totalThemes] })), effectedRows && effectedRows?.totalVouchers > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherVouchers"), " =", " ", effectedRows?.totalVouchers] })), effectedRows && effectedRows?.totalAnnualPlan > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherAnnualPlans"), " =", " ", effectedRows?.totalAnnualPlan] }))] }) })] }));
}
export default Board;
