import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { AgreementStatus, AgreementStatusResultMap, } from "@application/common/enums/agreement-status";
import { translate } from "@application/common/helpers/translate";
import { addAgreementAsync } from "@application/features/agreements/agreementThunk";
import { resetSchoolsDropdown } from "@application/features/board/boardSlice";
import { getBoardsDropdownAsync, getSchoolsDropdownAsync, } from "@application/features/board/boardThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Edit, FileUpload } from "@mui/icons-material";
import { Autocomplete, Box, Button, Grid, TextField, Typography, } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const AddAgreement = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { schoolDropdownList, boardDropdownList } = useAppSelector((state) => state.board);
    const [isDisableSchool, setIsDisabledSchool] = useState(true);
    useEffect(() => {
        dispatch(getBoardsDropdownAsync(true));
        dispatch(resetSchoolsDropdown([]));
    }, [dispatch]);
    const statusRequestList = [
        {
            id: Number(AgreementStatus.Sent).toString(),
            label: AgreementStatusResultMap.get(AgreementStatus.Sent),
        },
        {
            id: Number(AgreementStatus.Received).toString(),
            label: AgreementStatusResultMap.get(AgreementStatus.Received),
        },
    ];
    const { register, setValue, getValues, watch, control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            boardId: undefined,
            schoolId: undefined,
            submissionDate: undefined,
            contactPersonName: undefined,
            contactPersonEmail: "",
            status: undefined,
            startDate: undefined,
            endDate: undefined,
            agreementFile: undefined,
        },
    });
    const onSubmit = (credentials) => {
        dispatch(addAgreementAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/agreements");
            }
        });
    };
    const validateSchool = (schoolId) => {
        const boardIdValue = getValues("boardId");
        if (!boardIdValue)
            return true;
        const boardName = boardDropdownList.find((x) => x.id === boardIdValue)?.label;
        if (boardName === "Uncategorized" && schoolId === undefined) {
            return translate("validations.fieldIsRequired").replace("{field}", translate("schools.school"));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("agreements.agreement")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(Grid, { container: true, spacing: 2.5, justifyContent: "space-between", children: _jsx(Grid, { item: true, xs: 12, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "news-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: boardDropdownList && (_jsx(Controller, { name: "boardId", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("boards.board")),
                                                },
                                            }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: boardDropdownList, value: boardDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionLabel: (option) => option.label, onChange: (_, value) => {
                                                    if (value?.label !== "Uncategorized") {
                                                        setValue("boardId", value?.id);
                                                        setValue("schoolId", undefined);
                                                        setIsDisabledSchool(true);
                                                    }
                                                    else {
                                                        dispatch(getSchoolsDropdownAsync(true));
                                                        setIsDisabledSchool(false);
                                                    }
                                                    onChange(value?.id ?? "");
                                                }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("boards.board"), placeholder: translate("boards.board"), error: errors.boardId ? true : false, helperText: errors.boardId?.message })) })) })) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: schoolDropdownList && (_jsx(Controller, { name: "schoolId", control: control, rules: {
                                                validate: (value) => validateSchool(value),
                                            }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: schoolDropdownList, value: schoolDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionLabel: (option) => option.label, onChange: (_, value) => {
                                                    setValue("schoolId", value?.id);
                                                    onChange(value?.id ?? undefined);
                                                }, disabled: isDisableSchool, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("schools.school"), placeholder: translate("schools.school"), error: errors.schoolId ? true : false, helperText: errors.schoolId?.message })) })) })) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: _jsx(Controller, { name: "submissionDate", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("agreements.submissionDate")),
                                                },
                                            }, render: ({ field }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, { slotProps: {
                                                        textField: {
                                                            size: "small",
                                                            fullWidth: true,
                                                            error: !!errors.submissionDate,
                                                            helperText: errors.submissionDate?.message,
                                                        },
                                                    }, format: DateFormatConstants.MDY, label: translate("agreements.submissionDate"), value: getValues("submissionDate") === undefined
                                                        ? null
                                                        : dayjs(getValues("submissionDate")), onChange: (value) => setValue("submissionDate", value.toDate()) }) })) }) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: _jsx(TextField, { id: "Contact Person Name", label: translate("account.contactPersonName"), variant: "outlined", size: "small", placeholder: translate("account.contactPersonName"), type: "text", error: !!errors.contactPersonName, helperText: errors.contactPersonName?.message, fullWidth: true, ...register("contactPersonName", {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.contactPersonName")),
                                                },
                                            }) }) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: _jsx(TextField, { id: "Contact Person Email", label: translate("account.contactPersonEmail"), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), type: "text", error: !!errors.contactPersonEmail, helperText: errors.contactPersonEmail?.message, fullWidth: true, ...register("contactPersonEmail", {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.contactPersonEmail")),
                                                },
                                            }) }) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: statusRequestList && (_jsx(Controller, { name: "status", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("agreements.statusRequest")),
                                                },
                                            }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: statusRequestList, value: statusRequestList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, onChange: (_, value) => {
                                                    onChange(value ? Number(value?.id ?? undefined) : null);
                                                }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("agreements.statusRequest"), placeholder: translate("agreements.statusRequest"), error: errors.status ? true : false, helperText: errors.status?.message })) })) })) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: _jsx(Controller, { name: "startDate", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("agreements.startDate")),
                                                },
                                            }, render: ({ field }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, { slotProps: {
                                                        textField: {
                                                            size: "small",
                                                            fullWidth: true,
                                                            error: !!errors.startDate,
                                                            helperText: errors.startDate?.message,
                                                        },
                                                    }, format: DateFormatConstants.MDY, label: translate("agreements.startDate"), value: getValues("startDate") === undefined
                                                        ? null
                                                        : dayjs(getValues("startDate")), onChange: (value) => setValue("startDate", value.toDate()) }) })) }) }), _jsx(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: _jsx(Controller, { name: "endDate", control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("agreements.endDate")),
                                                },
                                            }, render: ({ field }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, { slotProps: {
                                                        textField: {
                                                            size: "small",
                                                            fullWidth: true,
                                                            error: !!errors.endDate,
                                                            helperText: errors.endDate?.message,
                                                        },
                                                    }, format: DateFormatConstants.MDY, label: translate("agreements.endDate"), value: getValues("endDate") === undefined
                                                        ? null
                                                        : dayjs(getValues("endDate")), onChange: (value) => setValue("endDate", value.toDate()) }) })) }) }), _jsxs(Grid, { item: true, lg: 4, md: 6, xs: 12, sm: 6, children: [_jsx(Controller, { name: `agreementFile`, control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("agreements.agreementFile")),
                                                    },
                                                }, render: ({ field }) => (_jsx(_Fragment, { children: _jsxs(Button, { component: "label", className: "Button", variant: "outlined", color: "primary", fullWidth: true, endIcon: watch(`agreementFile`) != undefined ? (_jsx(Edit, {})) : (_jsx(FileUpload, {})), children: [_jsx(Typography, { variant: "body1", sx: {
                                                                    whiteSpace: "normal",
                                                                    flex: "1 1 auto",
                                                                    wordBreak: "break-all",
                                                                }, children: watch(`agreementFile`) == undefined
                                                                    ? translate("cta.uploadFile")
                                                                    : watch(`agreementFile`).name }), _jsx("input", { id: `agreementFile`, type: "file", name: `agreementFile`, onChange: (e) => {
                                                                    const files = e.target.files != null
                                                                        ? e.target.files[0]
                                                                        : null;
                                                                    if (files) {
                                                                        setValue(`agreementFile`, files);
                                                                    }
                                                                    field.onChange(files);
                                                                }, style: { display: "none" } })] }) })) }), _jsx(WnValidation, { fieldError: errors.agreementFile })] }), _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) })] }) }) }) }) })] }));
};
export default AddAgreement;
