import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, Box, Button, Grid, TextField, Typography, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { addNewsAsync } from "@application/features/news/newsThunk";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getPortalDropdownAsync } from "@application/features/portal/portalThunk";
import dayjs from "dayjs";
import WNSwitch from "@ui/components/wn-switch";
import { translate } from "@application/common/helpers/translate";
import { PortalTypeStatusMap } from "@application/common/enums/portal-type";
const AddNews = () => {
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { portalDropdownList } = useAppSelector((state) => state.portal);
    useEffect(() => {
        dispatch(getPortalDropdownAsync());
    }, [dispatch]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isTodayDate, setIsTodayDate] = useState(false);
    const { register, setValue, getValues, control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            title: "",
            portalIds: [],
            publishDate: undefined,
            videoLink: "",
            description: "",
            picture: undefined,
        },
    });
    const onSubmit = (credentials) => {
        if (selectedImage === null || selectedImage === undefined) {
            dispatch(addErrorNotification(translate("validations.fieldIsRequired").replace("{field}", translate("common.featureImage"))));
            return;
        }
        credentials.picture = selectedImage;
        dispatch(addNewsAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/news");
            }
        });
    };
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                setSelectedImage(selectedFile);
            }
            else {
                dispatch(addErrorNotification(translate("validations.fileNotImage")));
                return;
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("news.news")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs(Box, { sx: {
                        display: "flex",
                        gap: 2.5,
                        flexDirection: { xs: "column", sm: "row", md: "row" },
                    }, children: [_jsx("div", { children: _jsxs("div", { className: "image-box theme", children: [_jsx("div", { className: "image-container", children: _jsx("img", { src: selectedImage ? URL.createObjectURL(selectedImage) : "", alt: "" }) }), _jsx("input", { type: "file", ref: fileInputRef, style: { display: "none" }, onChange: handleFileChange }), _jsx(Button, { className: "Button", variant: "outlined", color: "primary", sx: {
                                            width: "100%",
                                            borderTopLeftRadius: "0",
                                            borderTopRightRadius: "0",
                                        }, onClick: handleButtonClick, children: translate("cta.upload") }), _jsx(Typography, { variant: "h6", textAlign: "center", marginTop: 0.5, marginBottom: 1, children: translate("news.newsImage") })] }) }), _jsx("div", { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "news-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 4, md: 12, sm: 12, xs: 12, children: _jsx(TextField, { id: "title", label: translate("news.newsTitle"), variant: "outlined", size: "small", placeholder: translate("news.newsTitle"), type: "text", error: !!errors.title, helperText: errors.title?.message, fullWidth: true, ...register("title", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.newsTitle")),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, lg: 4, md: 12, sm: 12, xs: 12, children: portalDropdownList && (_jsx(Controller, { name: "portalIds", control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.targetPortals")),
                                                    },
                                                }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", multiple: true, filterSelectedOptions: true, options: portalDropdownList, value: value, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionLabel: (option) => PortalTypeStatusMap.get(option.portalType), onChange: (_, value) => {
                                                        onChange(value ?? []);
                                                    }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("news.targetPortals"), placeholder: translate("news.targetPortals"), error: errors.portalIds ? true : false, helperText: errors.portalIds?.message })) })) })) }), _jsxs(Grid, { item: true, lg: 4, md: 12, sm: 12, xs: 12, children: [_jsx(Controller, { name: "publishDate", control: control, rules: {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("news.publishDate")),
                                                        },
                                                    }, render: ({ field }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, { slotProps: {
                                                                textField: {
                                                                    size: "small",
                                                                    fullWidth: true,
                                                                    error: !!errors.publishDate,
                                                                    helperText: errors.publishDate?.message,
                                                                },
                                                                field: {
                                                                    readOnly: true,
                                                                },
                                                            }, format: DateFormatConstants.MDY, label: translate("news.publishDate"), value: getValues("publishDate") === undefined
                                                                ? null
                                                                : dayjs(getValues("publishDate")), onChange: (value) => {
                                                                if (value &&
                                                                    value.toDate() &&
                                                                    value?.toDate() > new Date()) {
                                                                    setIsTodayDate(false);
                                                                }
                                                                setValue("publishDate", value.toDate());
                                                            }, minDate: dayjs() }) })) }), _jsx(WNSwitch, { checked: isTodayDate, onChange: ($event) => {
                                                        setIsTodayDate($event);
                                                        if ($event) {
                                                            setValue("publishDate", new Date());
                                                        }
                                                        else {
                                                            setValue("publishDate", undefined);
                                                        }
                                                    }, label: translate("news.publishToday") }, "isTodayDate")] }), _jsx(Grid, { item: true, xs: 12, md: 12, sm: 12, children: _jsx(TextField, { id: "videoLink", label: translate("news.videoLink"), variant: "outlined", size: "small", placeholder: translate("news.videoLink"), type: "text", helperText: errors.videoLink?.message, error: !!errors.videoLink, fullWidth: true, ...register("videoLink", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("news.videoLink")),
                                                    },
                                                    pattern: {
                                                        value: /^(http|https):\/\/[^ "]+$/,
                                                        message: translate("validations.invalidUrl"),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, xs: 12, lg: 12, md: 12, sm: 12, children: _jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", helperText: errors.description?.message, error: !!errors.description, fullWidth: true, ...register("description", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) })] }) }) })] }) })] }));
};
export default AddNews;
