import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from "@application/common/helpers/translate";
import { getGoalDropdownAsync } from "@application/features/goal/goalThunk";
import { addSubGoalAsync } from "@application/features/sub-goal/subGoalThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const AddSubGoal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { goalDropdownList } = useAppSelector((state) => state.goal);
    const [selectedOption, setSelectedOption] = useState(null);
    const { register, setValue, setError, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            description: "",
            goalId: "",
        },
    });
    useEffect(() => {
        dispatch(getGoalDropdownAsync());
    }, [dispatch]);
    const onSubmit = (credentials) => {
        credentials.goalId = selectedOption?.id ?? "";
        dispatch(addSubGoalAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/sub-goals");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("subGoals.subGoal")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "goal-form", noValidate: true, children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "subGoalName", label: translate("subGoals.subGoalName"), variant: "outlined", size: "small", placeholder: translate("subGoals.subGoalName"), type: "text", fullWidth: true, error: !!errors.name, ...register("name", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("subGoals.subGoalName")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.name })] }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: goalDropdownList && (_jsxs(_Fragment, { children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedOption, onChange: (event, newValue) => {
                                                        setValue("goalId", newValue?.id ?? "");
                                                        setSelectedOption(newValue);
                                                        if (newValue !== null && newValue !== undefined) {
                                                            setError("goalId", {
                                                                type: "required",
                                                                message: undefined,
                                                            });
                                                        }
                                                    }, options: goalDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("subGoals.mainGoal"), placeholder: translate("subGoals.mainGoal"), variant: "outlined", ...register("goalId", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("subGoals.mainGoal")),
                                                            },
                                                        }), error: errors.goalId && selectedOption === null
                                                            ? true
                                                            : false })) }), _jsx(WnValidation, { fieldError: errors.goalId })] })) }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", fullWidth: true, error: !!errors.description, ...register("description", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.description })] })] }) }), _jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) }) }) })] }) })] }));
};
export default AddSubGoal;
