import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getCountryDropDownAsync } from "@application/features/country/countryThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Divider, Grid, TextField, Typography, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WNSwitch from "@ui/components/wn-switch";
import WnValidation from "@ui/components/wn-validation";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getSchoolAsync, updateSchoolAsync, } from "@application/features/school/schoolThunk";
import { useNavigate, useParams } from "react-router-dom";
import { getBoardsDropdownAsync } from "@application/features/board/boardThunk";
import { APIStatus } from "@application/common/enums/api-status";
import { translate } from "@application/common/helpers/translate";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { LicenseInvoiceType, LicenseInvoiceTypeStatusMap, } from "@application/common/enums/license-invoice-type";
import { SignedUpType, SignedUpTypeStatusMap, } from "@application/common/enums/signed-up-type";
const EditSchool = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { type } = useParams();
    const { boardDropdownList } = useAppSelector((state) => state.board);
    const { countryDropdownList } = useAppSelector((state) => state.country);
    const { school, status } = useAppSelector((state) => state.school);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Schools]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const invoiceToDropdownList = [
        {
            id: Number(LicenseInvoiceType.Management).toString(),
            label: LicenseInvoiceTypeStatusMap.get(LicenseInvoiceType.Management),
        },
        {
            id: Number(LicenseInvoiceType.School).toString(),
            label: LicenseInvoiceTypeStatusMap.get(LicenseInvoiceType.School),
        },
    ];
    const signedUpViaList = [
        {
            id: Number(SignedUpType.Wijzneus).toString(),
            label: SignedUpTypeStatusMap.get(SignedUpType.Wijzneus),
        },
        {
            id: Number(SignedUpType.Heutink).toString(),
            label: SignedUpTypeStatusMap.get(SignedUpType.Heutink),
        },
    ];
    const { register, control, setError, setValue, getValues, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            boardId: "",
            directorName: "",
            directorEmail: "",
            directorPhone: "",
            postalCode: "",
            countryId: "",
            address: "",
            invoiceSetting: {
                billingEmail: "",
                licensePurchaseNumber: "",
                licenseInvoiceTo: undefined,
                purchaseNumberAcademy: "",
                academyInvoiceTo: undefined,
            },
            registerationDate: new Date(),
            notes: "",
            signedUpVia: undefined,
            isWijzneusAcademy: false,
        },
    });
    useEffect(() => {
        dispatch(getBoardsDropdownAsync(true));
        dispatch(getCountryDropDownAsync());
    }, [dispatch]);
    useEffect(() => {
        if (type) {
            dispatch(getSchoolAsync(type));
        }
    }, [dispatch, type]);
    useEffect(() => {
        if (school && type && status == APIStatus.IDLE) {
            setValue("id", school.id);
            setValue("name", school.name);
            setValue("boardId", school.board.id);
            setValue("directorName", school.directorName);
            setValue("directorEmail", school.directorEmail);
            setValue("directorPhone", school.directorPhone);
            setValue("postalCode", school.postalCode);
            setValue("countryId", school.country.id);
            setValue("street", school.street);
            setValue("houseNumber", school.houseNumber);
            setValue("additionalInformation", school.additionalInformation);
            setValue("city", school.city);
            if (school.invoiceSetting) {
                setValue("invoiceSetting.billingEmail", school.invoiceSetting.billingEmail);
                setValue("invoiceSetting.licensePurchaseNumber", school.invoiceSetting.licensePurchaseNumber);
                setValue("invoiceSetting.licenseInvoiceTo", school.invoiceSetting.licenseInvoiceTo);
                setValue("invoiceSetting.purchaseNumberAcademy", school.invoiceSetting.purchaseNumberAcademy);
                setValue("invoiceSetting.academyInvoiceTo", school.invoiceSetting.academyInvoiceTo);
            }
            setValue("registerationDate", school.registerationDate);
            setValue("signedUpVia", school.signedUpVia);
            setValue("notes", school.notes);
            setValue("isWijzneusAcademy", school.isWijzneusAcademy);
            setValue("isParnassus", school.isParnassus);
        }
    }, [school, type, setValue]);
    const onSubmit = (updateSchoolDto) => {
        updateSchoolDto.id = type ?? "";
        dispatch(updateSchoolAsync(updateSchoolDto)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/schools");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                        ? translate("cta.editModule").replace("{module}", translate("schools.school"))
                        : translate("cta.viewModule").replace("{module}", translate("schools.school")) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "school-form", noValidate: true, children: status == APIStatus.IDLE && (_jsxs(_Fragment, { children: [_jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("account.generalInfo") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "name", label: translate("account.name"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("account.name"), error: !!errors.name, fullWidth: true, disabled: !hasEditPermission, ...register("name", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.name")),
                                                            },
                                                            maxLength: {
                                                                value: 100,
                                                                message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.name })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [boardDropdownList && (_jsx(Controller, { name: "boardId", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("boards.board")),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: boardDropdownList, value: boardDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                                onChange(value?.id ?? "");
                                                                // if (value == null || value == undefined) {
                                                                //   setError("boardId", {
                                                                //     type: "required",
                                                                //     message: translate(
                                                                //       "validations.fieldIsRequired"
                                                                //     ).replace("{field}", translate("boards.board")),
                                                                //   });
                                                                // }
                                                            }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "", shrink: true }, label: translate("boards.board"), placeholder: translate("boards.board"), error: errors.boardId ? true : false })) })) })), _jsx(WnValidation, { fieldError: errors.boardId })] }), _jsx(Grid, { item: true, md: 4, display: { xs: "none", sm: "none" } }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "director_name", label: translate("account.directorName"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("account.directorName"), error: !!errors.directorName, fullWidth: true, disabled: !hasEditPermission, ...register("directorName", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.directorName")),
                                                            },
                                                            maxLength: {
                                                                value: 100,
                                                                message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.directorName })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "director_email", label: translate("account.directorEmail"), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), type: "email", error: !!errors.directorEmail, disabled: !hasEditPermission, InputLabelProps: {
                                                            shrink: true,
                                                        }, fullWidth: true, ...register("directorEmail", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.directorEmail")),
                                                            },
                                                            maxLength: {
                                                                value: 100,
                                                                message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                            },
                                                            pattern: {
                                                                value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: translate("validations.invalidEmailAddress"),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.directorEmail })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "director_phone", label: translate("account.directorPhone"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("account.directorPhone"), fullWidth: true, disabled: !hasEditPermission, error: !!errors.directorPhone, ...register("directorPhone", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.directorPhone")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.directorPhone })] })] })] }), _jsx(Box, { marginTop: 3, marginBottom: 3, children: _jsx(Divider, {}) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("account.addressInfo") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "street", label: translate("account.street"), variant: "outlined", size: "small", placeholder: translate("account.street"), InputLabelProps: { shrink: true }, error: !!errors.street, fullWidth: true, disabled: !hasEditPermission, ...register("street", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.street")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.street })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "houseNumber", label: translate("account.houseNumber"), variant: "outlined", size: "small", placeholder: translate("account.houseNumber"), type: "text", InputLabelProps: { shrink: true }, error: !!errors.houseNumber, fullWidth: true, disabled: !hasEditPermission, ...register("houseNumber", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.houseNumber")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.houseNumber })] }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(TextField, { id: "additionalInformation", label: translate("account.additionalInformation"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("account.additionalInformation"), fullWidth: true, disabled: !hasEditPermission, ...register("additionalInformation", {}) }) }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "postal_code", label: translate("account.postalCode"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("account.postalCode"), type: "tel", fullWidth: true, disabled: !hasEditPermission, error: !!errors.postalCode, ...register("postalCode", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.postalCode")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.postalCode })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "city", label: translate("account.city"), variant: "outlined", size: "small", placeholder: translate("account.city"), fullWidth: true, InputLabelProps: { shrink: true }, disabled: !hasEditPermission, error: !!errors.city, ...register("city", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.city")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.city })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [countryDropdownList && (_jsx(Controller, { name: "countryId", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("account.country")),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: countryDropdownList, value: countryDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                                onChange(value?.id ?? "");
                                                            }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "", shrink: true }, label: translate("account.country"), placeholder: translate("account.country"), error: !!errors.countryId })) })) })), _jsx(WnValidation, { fieldError: errors.countryId })] })] })] }), _jsx(Box, { marginTop: 3, marginBottom: 3, children: _jsx(Divider, {}) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("invoiceSettings.invoiceSettings") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [_jsx(TextField, { id: "billing_email", label: translate("invoiceSettings.billingEmail"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("account.emailPlaceholder"), fullWidth: true, disabled: !hasEditPermission, error: !!errors.invoiceSetting?.billingEmail, ...register("invoiceSetting.billingEmail", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.billingEmail")),
                                                            },
                                                            maxLength: {
                                                                value: 100,
                                                                message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                            },
                                                            pattern: {
                                                                value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: translate("validations.invalidEmailAddress"),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.billingEmail })] }), _jsx(Grid, { item: true, lg: 6, md: 6, xs: 12, display: { xs: "none", sm: "none" } }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [_jsx(TextField, { id: "license_purchase_no", label: translate("invoiceSettings.licensePurchaseNumber"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("invoiceSettings.licensePurchaseNumber"), fullWidth: true, disabled: !hasEditPermission, error: !!errors.invoiceSetting?.licensePurchaseNumber, ...register("invoiceSetting.licensePurchaseNumber", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.licensePurchaseNumber")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.licensePurchaseNumber })] }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [invoiceToDropdownList && (_jsx(Controller, { name: "invoiceSetting.licenseInvoiceTo", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.licenseInvoiceTo")),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: invoiceToDropdownList, value: invoiceToDropdownList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                                onChange(value ? Number(value?.id ?? undefined) : null);
                                                            }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "", shrink: true }, label: translate("invoiceSettings.licenseInvoiceTo"), placeholder: translate("invoiceSettings.licenseInvoiceTo"), error: errors.invoiceSetting?.licenseInvoiceTo
                                                                    ? true
                                                                    : false })) })) })), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.licenseInvoiceTo })] }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [_jsx(TextField, { id: "purchase_number_academy", label: translate("invoiceSettings.purchaseNumberAcademy"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("invoiceSettings.purchaseNumberAcademy"), fullWidth: true, disabled: !hasEditPermission, error: !!errors.invoiceSetting?.purchaseNumberAcademy, ...register("invoiceSetting.purchaseNumberAcademy", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.purchaseNumberAcademy")),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.purchaseNumberAcademy })] }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [invoiceToDropdownList && (_jsx(Controller, { name: "invoiceSetting.academyInvoiceTo", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.academyInvoiceTo")),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: invoiceToDropdownList, value: invoiceToDropdownList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                                onChange(value ? Number(value?.id ?? undefined) : null);
                                                            }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "", shrink: true }, label: translate("invoiceSettings.academyInvoiceTo"), placeholder: translate("invoiceSettings.academyInvoiceTo"), error: errors.invoiceSetting?.academyInvoiceTo
                                                                    ? true
                                                                    : false })) })) })), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.academyInvoiceTo })] })] })] }), _jsx(Box, { marginTop: 3, marginBottom: 3, children: _jsx(Divider, {}) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("schools.otherInfo.otherInfo") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 4, md: 6, sm: 6, xs: 12, children: _jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, { label: translate("schools.otherInfo.registrationDate"), format: "MM/DD/YYYY", slotProps: {
                                                            textField: { size: "small", fullWidth: true },
                                                        }, value: dayjs(getValues("registerationDate")), disabled: !hasEditPermission, onChange: (value) => setValue("registerationDate", value.toDate()) }) }) }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [signedUpViaList && (_jsx(Controller, { name: "signedUpVia", control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("schools.otherInfo.signUpVia")),
                                                            },
                                                        }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: signedUpViaList, value: signedUpViaList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                                onChange(value ? Number(value?.id ?? undefined) : null);
                                                            }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("schools.otherInfo.signUpVia"), placeholder: translate("schools.otherInfo.signUpVia"), error: errors.signedUpVia ? true : false })) }, `signedupviakey`)) })), _jsx(WnValidation, { fieldError: errors.signedUpVia })] }), _jsx(Grid, { item: true, lg: 4, md: 6, sm: 6, xs: 12, children: _jsx(Controller, { name: "isWijzneusAcademy", control: control, defaultValue: false, render: ({ field: { onChange, value } }) => (_jsx(WNSwitch, { checked: value, onChange: onChange, label: translate("schools.otherInfo.wijzneusAcademy"), isTimeOutEnabled: false, disable: !hasEditPermission })) }) }), _jsxs(Grid, { item: true, lg: 8, md: 6, sm: 6, xs: 12, children: [_jsx(TextField, { id: "notes", label: translate("schools.otherInfo.notes"), variant: "outlined", size: "small", InputLabelProps: { shrink: true }, placeholder: translate("schools.otherInfo.notes"), error: !!errors.notes, fullWidth: true, disabled: !hasEditPermission, ...register("notes", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("schools.otherInfo.notes")),
                                                            },
                                                            maxLength: {
                                                                value: 250,
                                                                message: translate("validations.maximumCharactersAllowed").replace("{count}", "250"),
                                                            },
                                                        }) }), _jsx(WnValidation, { fieldError: errors.notes })] }), hasEditPermission && (_jsx(Grid, { item: true, xs: 12, textAlign: "right", children: _jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", type: "submit", children: translate("cta.saveChanges") }) }))] })] })] })) }) })] }));
};
export default EditSchool;
