import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BoardApi from "@infrastructure/board-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const getBoardsAsync = createAsyncThunk("board/getBoards", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await BoardApi.getBoards();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getBoardsDropdownAsync = createAsyncThunk("board/getBoardsDropdown", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await BoardApi.getBoardsDropdown(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSchoolsByBoardIdAsync = createAsyncThunk("board/getSchoolsByBoardId", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await BoardApi.getSchoolsByBoardId(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSchoolsDropdownAsync = createAsyncThunk("board/getSchoolDropdown", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await BoardApi.getSchoolDropdown(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getBoardByIdAsync = createAsyncThunk("board/getBoard", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await BoardApi.getBoard(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addBoardsAsync = createAsyncThunk("board/addBoard", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await BoardApi.addBoard(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("boards.board"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateBoardsAsync = createAsyncThunk("board/updateBoard", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await BoardApi.updateBoard(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("boards.board"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableBoardAsync = createAsyncThunk("board/enableDisableBoard", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await BoardApi.enableDisableBoard(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("boards.board"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const deleteBoardAsync = createAsyncThunk("board/deleteBoard", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await BoardApi.deleteBoard(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("boards.board"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getEffectedBoardAsync = createAsyncThunk("board/getEffectedBoard", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await BoardApi.getEffectedBoardResults(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
