import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addNewsAsync, enableDisableNewsAsync, getDownloadAndViewNewsAsync, getAdminPortalNewsAsync, getNewsAsync, getNewsByIdAsync, updateNewsAsync } from './newsThunk';
const initialState = {
    status: APIStatus.IDLE,
    news: [],
    //themeDropdownList: []
    newsFilterModel: {
        items: []
    }
};
export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsFilterModel(state, action) {
            state.newsFilterModel = action.payload || {
                items: []
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.news = action.payload;
        })
            .addCase(getNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getAdminPortalNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getAdminPortalNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.news = action.payload;
        })
            .addCase(getAdminPortalNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        // builder
        //     .addCase(getThemeDropdownListAsync.pending, (state) => {
        //         state.status = APIStatus.PENDING;
        //         state.apiError = undefined;
        //     })
        //     .addCase(getThemeDropdownListAsync.fulfilled, (state, action) => {
        //         state.status = APIStatus.IDLE;
        //         state.themeDropdownList = action.payload;
        //     })
        //     .addCase(getThemeDropdownListAsync.rejected, (state, action) => {
        //         state.status = APIStatus.FAILED;
        //         state.apiError = action.payload;
        //     });
        builder
            .addCase(getNewsByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getNewsByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.singleNews = action.payload;
        })
            .addCase(getNewsByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getDownloadAndViewNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDownloadAndViewNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.newsFile = action.payload;
        })
            .addCase(getDownloadAndViewNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableNewsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableNewsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableNewsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setNewsFilterModel } = newsSlice.actions;
export default newsSlice.reducer;
