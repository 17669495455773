import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addRoleAsync, updateRoleAsync, enableDisableRoleAsync, getRoleByIdAsync, getRolesAsync, getRoleDropDownAsync, } from "./roleThunk";
const initialState = {
    status: APIStatus.IDLE,
    roleFilterModel: {
        items: []
    }
};
export const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        setRoleFilterModel(state, action) {
            state.roleFilterModel = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRolesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getRolesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.roles = action.payload;
        })
            .addCase(getRolesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getRoleByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getRoleByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.role = action.payload;
        })
            .addCase(getRoleByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getRoleDropDownAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getRoleDropDownAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.roleDropdownList = action.payload;
        })
            .addCase(getRoleDropDownAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addRoleAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addRoleAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addRoleAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateRoleAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateRoleAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateRoleAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableRoleAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableRoleAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableRoleAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const { setRoleFilterModel } = roleSlice.actions;
export default roleSlice.reducer;
