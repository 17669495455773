import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SchoolApi from "@infrastructure/school-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const getSchoolsAsync = createAsyncThunk("school/getSchools", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getSchools();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getSchoolAsync = createAsyncThunk("school/getSchool", async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getSchool(id);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateSchoolStatusAsync = createAsyncThunk("school/updateSchoolStatus", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SchoolApi.updateSchoolStatus(request);
        dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("schools.school"))));
        dispatch(getSchoolsAsync());
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addSchoolAsync = createAsyncThunk("school/addSchool", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SchoolApi.addSchool(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("schools.school"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateSchoolAsync = createAsyncThunk("school/updateSchool", async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SchoolApi.updateSchool(request);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("schools.school"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeachersBySchoolIdAsync = createAsyncThunk("school/getTeacherBySchoolId", async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getTeachersBySchoolId(id);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const deleteSchoolAsync = createAsyncThunk("school/deleteSchool", async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await SchoolApi.deleteSchool(id);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("schools.school"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getEffectedSchoolAsync = createAsyncThunk("school/getEffectedSchool", async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await SchoolApi.getEffectedSchoolResults(id);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
