import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addUserAsync, updateUserAsync, enableDisableUserAsync, getUserByIdAsync, getUsersAsync, updateUserPasswordAsync, getEffectedRowsAsync, deleteTeacherUserAsync } from "./userThunk";
const initialState = {
    status: APIStatus.IDLE,
    isOpenDialog: false,
    isDeleteOpen: false,
    usersFilterModel: {
        items: []
    },
    deleteUserResult: false
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setOpenDialog(state, action) {
            state.isOpenDialog = action.payload;
        },
        setDeleteOpenDialog(state, action) {
            state.isDeleteOpen = action.payload;
        },
        setEffectedRows(state, action) {
            state.effectedRows = action.payload;
        },
        setUsersFilterModel(state, action) {
            state.usersFilterModel = action.payload || {
                items: []
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsersAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUsersAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.users = action.payload;
        })
            .addCase(getUsersAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.user = action.payload;
        })
            .addCase(getUserByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addUserAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateUserAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateUserPasswordAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateUserPasswordAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateUserPasswordAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableUserAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getEffectedRowsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getEffectedRowsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.effectedRows = action.payload;
        })
            .addCase(getEffectedRowsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(deleteTeacherUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteTeacherUserAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.deleteUserResult = action.payload;
        })
            .addCase(deleteTeacherUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const { setOpenDialog, setUsersFilterModel, setDeleteOpenDialog, setEffectedRows } = userSlice.actions;
export default userSlice.reducer;
