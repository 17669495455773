import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Collapse, IconButton, } from "@mui/material";
import "./style.scss";
import { useEffect, useState } from "react";
import { useAppSelector } from "@application/store/useStore";
import { NavLink } from "react-router-dom";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
const Sidebar = ({ isDrawer, handleClose }) => {
    const { isToggle } = useAppSelector((state) => state.toggle);
    const { currentUser } = useAppSelector((state) => state.auth);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const [openUsersAndPermissions, setUsersAndPermissionsOpen] = useState(false);
    const handleUsersAndPermissionsClick = () => {
        setUsersAndPermissionsOpen(!openUsersAndPermissions);
    };
    const hasViewPermissionForModule = (module) => {
        const modulePermissions = loggedInUserRolePermission &&
            validateModulePermission(loggedInUserRolePermission, module);
        return modulePermissions
            ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
            : false;
    };
    // Arrays for modules before dividers
    const firstSectionModules = [
        Module[Module.Dashboard],
        Module[Module.Boards],
        Module[Module.Schools],
        Module[Module["Teachers & Supervisors"]],
    ];
    const secondSectionModules = [
        Module[Module.Goals],
        Module[Module["Sub-Goals"]],
        Module[Module.Themes],
        Module[Module.Lessons],
        Module[Module.Vouchers],
    ];
    const thirdSectionModules = [Module[Module.News], Module[Module.FAQs]];
    const fourthSectionModules = [
        Module[Module.Agreements],
        //Module[Module.Invoices],
        Module[Module.Users],
        Module[Module["Role Permissions"]],
    ];
    // Function to check if at least one module in the array has view permission
    const atLeastOneModuleHasViewPermission = (modulesArray) => {
        return modulesArray.some((module) => hasViewPermissionForModule(module));
    };
    useEffect(() => {
        console.log(isToggle, "togle");
    }, [isToggle]);
    return (_jsx("aside", { style: {
            paddingTop: isDrawer ? "0px" : "",
        }, children: _jsxs(Box, { className: isToggle ? "sidebar-collapsed" : "sidebar-open", role: "presentation", children: [isDrawer && (_jsx(Box, { sx: { display: "flex", justifyContent: "end" }, children: _jsx(IconButton, { onClick: handleClose, children: _jsx(ChatCollapseIcon, {}) }) })), _jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Dashboard]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/dashboard", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: translate("dashboard.dashboard") }) }), _jsx(ListItemText, { primary: `Dashboard`, sx: { whiteSpace: "nowrap" } })] }) }, "Dashboard") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Boards]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/boards", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: "corporate_fare" }) }), _jsx(ListItemText, { primary: translate("boards.boards"), sx: { whiteSpace: "nowrap" } })] }) }, "Boards") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Schools]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/schools", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: "school" }) }), _jsx(ListItemText, { primary: translate("schools.schools"), sx: { whiteSpace: "nowrap" } })] }) }, "Schools") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module["Teachers & Supervisors"]]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/teachers", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded fill", children: "local_library" }) }), _jsx(ListItemText, { primary: translate("teachers&Supervisors.teachers&Supervisors"), sx: { whiteSpace: "nowrap" } })] }) }, "Teachers & Supervisors") }))] }), (atLeastOneModuleHasViewPermission(secondSectionModules) ||
                    atLeastOneModuleHasViewPermission(thirdSectionModules) ||
                    atLeastOneModuleHasViewPermission(fourthSectionModules)) && (_jsx(Divider, {})), atLeastOneModuleHasViewPermission(secondSectionModules) && (_jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Goals]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/goals", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "account_box" }) }), _jsx(ListItemText, { primary: translate("goals.goals"), sx: { whiteSpace: "nowrap" } })] }) }, "goals") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module["Sub-Goals"]]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/sub-goals", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "flag_circle" }) }), _jsx(ListItemText, { primary: translate("subGoals.subGoals"), sx: { whiteSpace: "nowrap" } })] }) }, "subgoals") })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Themes]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/themes", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "celebration" }) }), _jsx(ListItemText, { primary: translate("themes.themes"), sx: { whiteSpace: "nowrap" } })] }) }, `Themes`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Lessons]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/lessons", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "auto_stories" }) }), _jsx(ListItemText, { primary: translate("lessons.lessons"), sx: { whiteSpace: "nowrap" } })] }) }, `Lessons`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Vouchers]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/vouchers", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "data_array" }) }), _jsx(ListItemText, { primary: translate("vouchers.vouchers"), sx: { whiteSpace: "nowrap" } })] }) }, "Vouchers") }))] })), atLeastOneModuleHasViewPermission(secondSectionModules) && _jsx(Divider, {}), atLeastOneModuleHasViewPermission(thirdSectionModules) && (_jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.News]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/news", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "full_coverage" }) }), _jsx(ListItemText, { primary: translate("news.news"), sx: { whiteSpace: "nowrap" } })] }) }, `News`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.FAQs]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/faqs", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "help" }) }), _jsx(ListItemText, { primary: translate("faqs.faqs"), sx: { whiteSpace: "nowrap" } })] }) }, `FAQs`) }))] })), atLeastOneModuleHasViewPermission(thirdSectionModules) && _jsx(Divider, {}), _jsxs(List, { children: [loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            hasViewPermissionForModule(Module[Module.Agreements]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/agreements", className: ({ isActive }) => isActive
                                ? currentUser?.role.toLowerCase() == "superadmin" ||
                                    currentUser?.role.toLowerCase() == "admin"
                                    ? "active"
                                    : "board-active"
                                : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                        borderRadius: "12px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                    }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "handshake" }) }), _jsx(ListItemText, { primary: translate("agreements.agreements"), sx: { whiteSpace: "nowrap" } })] }) }, `Agreements`) })), loggedInUserRolePermission &&
                            loggedInUserRolePermission.length > 0 &&
                            (hasViewPermissionForModule(Module[Module.Users]) ||
                                hasViewPermissionForModule(Module[Module["Role Permissions"]])) && (_jsxs(_Fragment, { children: [_jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: {
                                            borderRadius: "12px",
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                        }, 
                                        // disabled={
                                        //   loggedInUserRolePermission &&
                                        //   loggedInUserRolePermission.length > 0 &&
                                        //   !hasViewPermissionForModule(Module[Module.Users]) &&
                                        //   !hasViewPermissionForModule(
                                        //     Module[Module["Role Permissions"]]
                                        //   )
                                        // }
                                        onClick: handleUsersAndPermissionsClick, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "lock" }) }), _jsx(ListItemText, { primary: translate("permissions.users&Permissions"), sx: { whiteSpace: "nowrap" } }), openUsersAndPermissions ? (_jsx("span", { className: "material-symbols-rounded", style: { float: "right" }, children: "expand_less" })) : (_jsx("span", { className: "material-symbols-rounded", children: "expand_more" }))] }) }, `Users & Permissions`), _jsx(Collapse, { in: openUsersAndPermissions, timeout: "auto", unmountOnExit: true, children: _jsxs(List, { component: "div", disablePadding: true, children: [loggedInUserRolePermission &&
                                                loggedInUserRolePermission.length > 0 &&
                                                hasViewPermissionForModule(Module[Module.Users]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/users", className: ({ isActive }) => isActive
                                                    ? currentUser?.role.toLowerCase() ==
                                                        "superadmin" ||
                                                        currentUser?.role.toLowerCase() == "admin"
                                                        ? "active"
                                                        : "board-active"
                                                    : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: { pl: 4 }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "account_box" }) }), _jsx(ListItemText, { primary: translate("users.users"), sx: { whiteSpace: "nowrap" } })] }) }, "Users") })), loggedInUserRolePermission &&
                                                loggedInUserRolePermission.length > 0 &&
                                                hasViewPermissionForModule(Module[Module["Role Permissions"]]) && (_jsx(NavLink, { onClick: handleClose, to: "admin/permissions", className: ({ isActive }) => isActive
                                                    ? currentUser?.role.toLowerCase() ==
                                                        "superadmin" ||
                                                        currentUser?.role.toLowerCase() == "admin"
                                                        ? "active"
                                                        : "board-active"
                                                    : "", children: _jsx(ListItem, { disablePadding: true, sx: { borderRadius: "16px" }, children: _jsxs(ListItemButton, { sx: { pl: 4 }, children: [_jsx(ListItemIcon, { children: _jsx("span", { className: "material-symbols-rounded", children: "key" }) }), _jsx(ListItemText, { primary: translate("permissions.permissions"), sx: { whiteSpace: "nowrap" } })] }) }, "Permissions") }))] }) })] }))] })] }) }));
};
export default Sidebar;
const ChatCollapseIcon = (props) => (_jsxs("svg", { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: [_jsx("path", { opacity: '0.32', d: 'M14.3283 11.4343L18.5126 7.24998C18.9268 6.83578 18.9268 6.16418 18.5126 5.74998C18.0984 5.33578 17.4268 5.33578 17.0126 5.74998L11.4696 11.2929C11.0792 11.6834 11.0792 12.3166 11.4696 12.7071L17.0126 18.25C17.4268 18.6642 18.0984 18.6642 18.5126 18.25C18.9268 17.8358 18.9268 17.1642 18.5126 16.75L14.3283 12.5657C14.254 12.4914 14.195 12.4032 14.1548 12.3061C14.1146 12.2091 14.0939 12.105 14.0939 12C14.0939 11.8949 14.1146 11.7909 14.1548 11.6938C14.195 11.5968 14.254 11.5086 14.3283 11.4343Z', fill: '#637381' }), _jsx("path", { d: 'M8.32826 11.4343L12.5126 7.24998C12.9268 6.83578 12.9268 6.16418 12.5126 5.74998C12.0984 5.33578 11.4268 5.33578 11.0126 5.74998L5.46956 11.2929C5.07916 11.6834 5.07916 12.3166 5.46956 12.7071L11.0126 18.25C11.4268 18.6642 12.0984 18.6642 12.5126 18.25C12.9268 17.8358 12.9268 17.1642 12.5126 16.75L8.32826 12.5657C8.25397 12.4914 8.19503 12.4032 8.15483 12.3061C8.11462 12.2091 8.09393 12.105 8.09393 12C8.09393 11.8949 8.11462 11.7909 8.15483 11.6938C8.19503 11.5968 8.25397 11.5086 8.32826 11.4343Z', fill: '#637381' })] }));
