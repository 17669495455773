import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Button, Grid, IconButton, Link, Container, useMediaQuery, useTheme, Menu, } from "@mui/material";
import "./style.scss";
import Logo from "../../../../logo.svg";
import { Bars2Icon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { logout } from "@application/features/auth/authSlice";
import { toggleMenu } from "@application/features/common/toggleSlice";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import { useNavigate } from "react-router-dom";
import { translate } from "@application/common/helpers/translate";
import { Icon } from "@iconify/react";
import { RoleEnum } from "@application/common/enums/role";
import React from "react";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";
function Header({ handleDrawer }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentUser } = useAppSelector((state) => state.auth);
    const isxl = useMediaQuery("(min-width:1200px) and (max-width:2500px)");
    const islg = useMediaQuery("(min-width:900px) and (max-width:1200px)");
    const isSm = useMediaQuery("(min-width:100px) and (max-width:580px)");
    const is12_9 = useMediaQuery("(min-width:900px) and (max-width:1150px)");
    const is900_580 = useMediaQuery("(min-width:580px) and (max-width:900px)");
    const is913_1150 = useMediaQuery("(min-width:913px) and (max-width:1150px)");
    const is1150_1200 = useMediaQuery("(min-width:1150px) and (max-width:1200px)");
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (isxl || is1150_1200)
            return;
        if (!is900_580 || is12_9)
            setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsx("header", { children: _jsx(Container, { maxWidth: "xl", sx: {
                paddingLeft: isSm ? 1 : 2,
                paddingRight: isSm ? 1 : 2,
            }, children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", sx: {
                    padding: { xs: "1rem 4px", sm: "1rem 16px", md: "1rem 24px" },
                    backgroundColor: "white.main",
                }, children: [_jsx(Grid, { item: true, sm: "auto", children: _jsxs(Box, { sx: { display: "flex", alignItems: "center" }, gap: islg ? 1 : isSm ? "4px" : 2, children: [_jsx(Box, { children: _jsx(IconButton, { size: isMd ? "small" : "medium", onClick: () => {
                                            if (isMd || is12_9) {
                                                if (is913_1150)
                                                    dispatch(toggleMenu());
                                                else
                                                    handleDrawer();
                                            }
                                            else {
                                                dispatch(toggleMenu());
                                            }
                                        }, sx: { backgroundColor: "inputBg.main" }, "aria-label": "humburger", children: _jsx(Bars2Icon, { width: isSm ? 24 : 36, height: isSm ? 24 : 36 }) }) }), _jsx(Link, { sx: { display: "block" }, children: _jsx("img", { height: 50, width: isSm ? "90px" : "", src: Logo, alt: "logo" }) })] }) }), _jsx(Grid, { item: true, sm: "auto", children: _jsxs(Box, { sx: {
                                display: "flex",
                                alignItems: "center",
                                gap: islg ? ".5rem" : isSm ? "4px" : "1rem",
                            }, children: [currentUser?.role == RoleEnum[RoleEnum.SuperAdmin] &&
                                    (isMd ? (_jsx(IconButton, { LinkComponent: Link, color: "success", onClick: () => window.open("https://www.wijzneus.nl/?edit", "_blank"), children: _jsx(Icon, { icon: "fa6-brands:webflow", fontSize: "18px" }) })) : (_jsx(Button, { LinkComponent: Link, className: "Button", onClick: () => window.open("https://www.wijzneus.nl/?edit", "_blank"), variant: "outlined", color: "success", startIcon: _jsx(Icon, { icon: "fa6-brands:webflow" }), children: translate("menu.webCMS") }))), isMd ? (_jsx(IconButton, { onClick: () => window.open("https://www.wijzneus.nl", "_blank"), color: "success", children: _jsx(Icon, { icon: "solar:home-smile-bold" }) })) : (_jsx(Button, { LinkComponent: Link, className: "Button", onClick: () => window.open("https://www.wijzneus.nl", "_blank"), variant: "outlined", color: "success", startIcon: _jsx(Icon, { icon: "solar:home-smile-bold" }), children: translate("menu.home") })), isMd ? (_jsx(IconButton, { LinkComponent: Link, onClick: () => window.open("https://portaal.wijzneus.nl", "_blank"), color: "primary", children: _jsx(ChildCareIcon, {}) })) : (_jsx(Button, { LinkComponent: Link, className: "Button", onClick: () => window.open("https://portaal.wijzneus.nl", "_blank"), color: "primary", variant: "outlined", startIcon: _jsx(ChildCareIcon, {}), children: translate("menu.kidsPortal") })), isMd
                                    ? !isSm && (_jsx(IconButton, { size: "small", onClick: () => {
                                            dispatch(logout());
                                            navigate("/login");
                                        }, color: "error", children: _jsx("span", { className: "material-symbols-rounded", children: "logout" }) }))
                                    : !is12_9 && (_jsxs(Button, { LinkComponent: Link, className: "Button", onClick: () => {
                                            dispatch(logout());
                                            navigate("/login");
                                        }, color: "error", variant: "outlined", children: [_jsx("span", { className: "material-symbols-rounded", children: "logout" }), translate("menu.logout")] })), _jsxs(Box, { display: "flex", alignItems: "center", children: [_jsx(Avatar, { onClick: handleClick, alt: currentUser?.name, sx: { width: isSm ? 30 : 40, height: isSm ? 30 : 40 }, src: "/static/images/avatar/1.jpg" }), _jsx(ArrowDropDownIcon, { color: "disabled", style: { display: is900_580 || is1150_1200 ? "none" : "" }, sx: { display: { md: "block", lg: "none" } } })] }), _jsx(Menu, { anchorEl: anchorEl, id: "account-menu", open: open, onClose: handleClose, onClick: handleClose, slotProps: {
                                        paper: {
                                            elevation: 0,
                                            sx: {
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                borderRadius: 0.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                "&::before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                },
                                            },
                                        },
                                    }, transformOrigin: { horizontal: "right", vertical: "top" }, anchorOrigin: { horizontal: "right", vertical: "bottom" }, children: _jsx(Box, { width: 150, px: 1, children: _jsxs(Button, { LinkComponent: Link, className: "Button", onClick: () => {
                                                dispatch(logout());
                                                navigate("/login");
                                                handleClose();
                                            }, color: "error", variant: "outlined", children: [_jsx("span", { className: "material-symbols-rounded", children: "logout" }), translate("menu.logout")] }) }) })] }) })] }) }) }));
}
export default Header;
