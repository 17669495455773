import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, Box, Button, Grid, TextField, Typography, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { getDownloadAndViewThemeAsync, getThemeByIdAsync, updateThemeAsync, } from "@application/features/theme/themeThunk";
import { useNavigate, useParams } from "react-router-dom";
import { getSeasonDropdownAsync } from "@application/features/season/seasonThunk";
import { PermissionType } from "@application/common/enums/permission-type";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
const EditTheme = () => {
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const [loading, setLoading] = useState(false);
    const { seasonDropDownList } = useAppSelector((state) => state.season);
    const { theme } = useAppSelector((state) => state.theme);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Themes]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getSeasonDropdownAsync());
    }, [dispatch]);
    useEffect(() => {
        if (type) {
            fetchDataAndDispatch();
        }
    }, [dispatch, type]);
    const fetchDataAndDispatch = async () => {
        try {
            // Dispatch an action
            if (type) {
                await dispatch(getThemeByIdAsync(type));
                setLoading(true);
            }
            // The following code will run after the dispatch is completed
            console.log("Dispatch completed!");
            // Your additional logic here
        }
        catch (error) {
            console.error("Error during dispatch:", error);
        }
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(undefined);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const { register, setError, setValue, formState: { errors }, handleSubmit, } = useForm({});
    const onSubmit = (credentials) => {
        credentials.id = type ?? "";
        credentials.featureImage = selectedImage ?? undefined;
        credentials.seasonId = selectedSeason?.id ?? "";
        dispatch(updateThemeAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/themes");
            }
        });
    };
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const handleFileChange = (event) => {
        setImageFile(undefined);
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                setSelectedImage(selectedFile);
            }
            else {
                dispatch(addErrorNotification(translate("validations.fileNotImage")));
                return;
            }
        }
    };
    useEffect(() => {
        if (theme && loading) {
            setValue("name", theme.name);
            setValue("description", theme.description);
            setValue("seasonId", theme.season.id);
            setSelectedSeason(theme.season);
            setValue("seasonId", theme.season.id);
            const fileName = theme.featuredImage.split("/")[1];
            dispatch(getDownloadAndViewThemeAsync(fileName)).then((res) => {
                if (res.payload !== null &&
                    res.payload !== undefined &&
                    res.payload !== "") {
                    const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                    setImageFile(base64Data);
                }
            });
        }
    }, [dispatch, setValue, theme, loading]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                            ? translate("cta.editModule").replace("{module}", translate("themes.theme"))
                            : translate("cta.viewModule").replace("{module}", translate("themes.theme")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs(Grid, { container: true, spacing: 2.5, justifyContent: "space-between", children: [_jsx(Grid, { item: true, lg: 3, md: 5, sm: 6, xs: 12, children: _jsxs("div", { className: "image-box theme", children: [_jsx("div", { className: "image-container", children: _jsx("img", { src: selectedImage
                                                ? URL.createObjectURL(selectedImage)
                                                : imageFile
                                                    ? imageFile
                                                    : "", alt: "" }) }), _jsx("input", { type: "file", ref: fileInputRef, style: { display: "none" }, onChange: handleFileChange }), hasEditPermission && (_jsx(Button, { className: "Button", variant: "outlined", color: "primary", sx: {
                                            width: "100%",
                                            borderTopLeftRadius: "0",
                                            borderTopRightRadius: "0",
                                        }, onClick: handleButtonClick, children: translate("cta.upload") })), _jsx(Typography, { variant: "h6", textAlign: "center", marginTop: 0.5, marginBottom: 1, children: translate("common.featureImage") }), hasEditPermission && (_jsxs(Typography, { variant: "body1", textAlign: "center", children: [translate("themes.themeImagePreview"), _jsx("br", {}), translate("themes.themeImagePreviewSizeLimit")] }))] }) }), _jsx(Grid, { item: true, lg: 8, md: 7, sm: 6, xs: 12, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "theme-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(TextField, { id: "name", label: translate("themes.themeName"), variant: "outlined", size: "small", placeholder: translate("themes.themeName"), type: "text", error: !!errors.name, helperText: errors.name?.message, InputLabelProps: { shrink: true }, fullWidth: true, disabled: !hasEditPermission, ...register("name", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("themes.themeName")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedSeason, disabled: !hasEditPermission, onChange: (event, newValue) => {
                                                    setValue("seasonId", newValue?.id ?? "");
                                                    setSelectedSeason(newValue);
                                                    if (newValue !== null && newValue !== undefined) {
                                                        setError("seasonId", {
                                                            type: "required",
                                                            message: undefined,
                                                        });
                                                    }
                                                }, options: seasonDropDownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "", shrink: true }, label: translate("themes.season"), placeholder: translate("themes.season"), variant: "outlined", ...register("seasonId", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("themes.season")),
                                                        },
                                                    }), error: errors.seasonId && selectedSeason === null
                                                        ? true
                                                        : false, helperText: errors.seasonId?.message })) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", error: !!errors.description, helperText: errors.description?.message, InputLabelProps: { shrink: true }, fullWidth: true, disabled: !hasEditPermission, ...register("description", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }) }), hasEditPermission && (_jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }))] }) }) })] }) })] }));
};
export default EditTheme;
