import { wijzneusApi } from './wijzneus-api';
const getBoards = () => {
    return wijzneusApi.get(`boards`);
};
const getSchoolsByBoardId = (request) => {
    return wijzneusApi.get(`boards/${request}/schools`);
};
const getBoard = (request) => {
    return wijzneusApi.get(`boards/${request}`);
};
const getBoardsDropdown = (request) => {
    return wijzneusApi.get(`boards/dropdown/${request}`);
};
const getSchoolDropdown = (status) => {
    return wijzneusApi.get(`boards/school-dropdown/${status}`);
};
const addBoard = (request) => {
    return wijzneusApi.post(`boards`, request);
};
const updateBoard = (request) => {
    return wijzneusApi.put(`boards`, request);
};
const enableDisableBoard = (request) => {
    return wijzneusApi.patch(`boards`, request);
};
const deleteBoard = (boardId) => {
    return wijzneusApi.delete(`boards/${boardId}/delete`);
};
const getEffectedBoardResults = (request) => {
    return wijzneusApi.get(`boards/${request}/effected-data`);
};
const BoardApi = {
    getBoards,
    getBoard,
    addBoard,
    updateBoard,
    enableDisableBoard,
    getBoardsDropdown,
    getSchoolDropdown,
    getSchoolsByBoardId,
    deleteBoard,
    getEffectedBoardResults
};
export default BoardApi;
