import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery, useTheme, } from "@mui/material";
import { Add, DeleteForever, FilterAltOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect, useState } from "react";
import { enableDisableTeacherAsync, getTeachersAsync, } from "@application/features/teacher/teacherThunk";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import "./style.scss";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { resetFilter } from "@application/features/common/filterResetSlice";
import { setTeacherFilterModel } from "@application/features/teacher/teacherSlice";
import AlertDialog from "@ui/components/wn-dialog";
import { setDeleteOpenDialog, setEffectedRows, } from "@application/features/user/userSlice";
import { deleteTeacherUserAsync, getEffectedRowsAsync, } from "@application/features/user/userThunk";
const Teachers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const { teachers, teacherFilterModel, status } = useAppSelector((state) => state.teacher);
    const { isDeleteOpen, effectedRows } = useAppSelector((state) => state.user);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const [deletedUserId, setDeletedUserId] = useState();
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module["Teachers & Supervisors"]]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const hasDeletePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.deletePermission]]
        : false;
    useEffect(() => {
        dispatch(getTeachersAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(resetFilter("teachers"));
    }, [dispatch]);
    const onEnableDisable = ($event, id) => {
        dispatch(enableDisableTeacherAsync({ id: id, status: $event })).then((res) => {
            if (res.payload === true) {
                dispatch(getTeachersAsync());
            }
        });
    };
    const handleConfirmDeleteAction = async () => {
        if (deletedUserId) {
            const result = await dispatch(deleteTeacherUserAsync(deletedUserId));
            if (result.payload === true) {
                dispatch(getTeachersAsync());
            }
        }
        handleDeleteCloseDialog();
    };
    const handleDeleteOpenDialog = (params) => {
        setDeletedUserId(params.id);
    };
    const handleDeleteCloseDialog = () => {
        setDeletedUserId("");
        dispatch(setEffectedRows(undefined));
        dispatch(setDeleteOpenDialog(false));
    };
    useEffect(() => {
        if (deletedUserId && deletedUserId !== "") {
            dispatch(getEffectedRowsAsync(deletedUserId));
        }
    }, [dispatch, deletedUserId]);
    useEffect(() => {
        if (effectedRows) {
            dispatch(setDeleteOpenDialog(true));
        }
    }, [dispatch, effectedRows]);
    const columns = [
        {
            field: "name",
            headerName: translate("account.name").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "school",
            headerName: translate("schools.school").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.school.name}`,
        },
        {
            field: "email",
            headerName: translate("account.email").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
        },
        {
            field: "role",
            headerName: translate("account.role").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 130 : undefined,
            valueGetter: (params) => {
                const roleName = params.row.role.name;
                const translatedRoleName = translate(`teachers&Supervisors.${roleName.toLowerCase()}`);
                return translatedRoleName;
            },
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 120 : undefined,
            filterable: false,
            hideable: false,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, disable: !params.row.school.isActive, onChange: ($event) => onEnableDisable($event, params.row.id) }, params.row.id)),
        },
        {
            field: "deleteUser",
            headerName: translate("account.delete").toUpperCase(),
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(Tooltip, { title: translate("account.delete"), arrow: true, placement: "top", children: _jsx(IconButton, { size: "medium", color: "error", children: _jsx(DeleteForever, { onClick: () => handleDeleteOpenDialog(params.row) }) }) })),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            hideSortIcons: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => navigate(`${params.row.id}`), children: translate("cta.details") })),
        },
    ];
    const onFilterModelChange = (model) => dispatch(setTeacherFilterModel(model));
    const onClearFilterModel = () => dispatch(setTeacherFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("teachers&Supervisors.teachers&Supervisors") }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })), hasCreatePermission && (isxs ?
                                _jsx(IconButton, { size: "medium", onClick: () => navigate("add"), children: _jsx(Add, { color: "primary", fontSize: "medium" }) })
                                :
                                    _jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") }))] })] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(Box, { sx: { width: "100%" }, children: _jsx(DataGrid, { componentsProps: { filterPanel: { sx: { maxWidth: "85vw" } } }, autoHeight: true, rows: teachers ?? [], columns: columns, filterModel: teacherFilterModel, onFilterModelChange: onFilterModelChange, hideFooterPagination: !teachers?.length, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                            "": (hasViewPermission || hasEditPermission) ?? true,
                            deleteUser: hasDeletePermission === true ? true : false
                        } }, "teacher-grid") }) }), _jsx(AlertDialog, { open: isDeleteOpen, onClose: handleDeleteCloseDialog, title: translate("deleteUserDialog.title"), content: translate("deleteUserDialog.content"), onConfirm: handleConfirmDeleteAction, onCancel: handleDeleteCloseDialog, confirmButtonText: translate("cta.proceed"), cancelButtonText: translate("cta.cancel"), isDeleted: true, description: _jsxs("div", { children: [_jsx("p", { children: translate("deleteUserDialog.followingData") }), effectedRows && effectedRows?.totalCourses > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherCourses"), " =", " ", effectedRows?.totalCourses] })), effectedRows && effectedRows?.totalThemes > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherThemes"), " =", " ", effectedRows?.totalThemes] })), effectedRows && effectedRows?.totalVouchers > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherVouchers"), " =", " ", effectedRows?.totalVouchers] })), effectedRows && effectedRows?.totalAnnualPlan > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherAnnualPlans"), " =", " ", effectedRows?.totalAnnualPlan] }))] }) })] }));
};
export default Teachers;
