import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppConsts } from "@application/common/constants/app-consts";
import { APIStatus } from "@application/common/enums/api-status";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { RoleEnum } from "@application/common/enums/role";
import { newRolePermissions, validateModulePermission, } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { resetFilter } from "@application/features/common/filterResetSlice";
import { setRolePermissionFilterModel } from "@application/features/role-permission/rolePermissionSlice";
import { getRolePermissionByIdAsync, updateRolePermissionsAsync, } from "@application/features/role-permission/rolePermissionThunk";
import { getRoleByIdAsync } from "@application/features/role/roleThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { FilterAltOff } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GoBack from "@ui/components/wn-go-back";
import WnNoRecordFound from "@ui/components/wn-no-record";
import WNSwitch from "@ui/components/wn-switch";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
const EditRolePermission = () => {
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const navigate = useNavigate();
    const { role } = useAppSelector((state) => state.role);
    const { rolePermission, loggedInUserRolePermission, status, rolePermissionFilterModel, } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module["Role Permissions"]]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const [rolePermissions, setRolePermissions] = useState(rolePermission ?? newRolePermissions());
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "moduleDisplayName",
            headerName: translate("permissions.modules").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "viewPermission",
            headerName: translate("permissions.view").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.viewPermission, disable: !hasEditPermission || role?.name == RoleEnum[RoleEnum.SuperAdmin]
                    ? true
                    : params.row.createPermission ||
                        params.row.editPermission ||
                        params.row.deletePermission ||
                        params.row.module === Module[Module.Dashboard], onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.viewPermission], params.row.module) }, params.row.id)),
        },
        {
            field: "createPermission",
            headerName: translate("permissions.create").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.createPermission, disable: !hasEditPermission || role?.name == RoleEnum[RoleEnum.SuperAdmin]
                    ? true
                    : params.row.editPermission || params.row.deletePermission, onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.createPermission], params.row.module) }, params.row.id)),
        },
        {
            field: "editPermission",
            headerName: translate("permissions.edit").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.editPermission, disable: !hasEditPermission || role?.name == RoleEnum[RoleEnum.SuperAdmin]
                    ? true
                    : params.row.deletePermission, onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.editPermission], params.row.module) }, params.row.id)),
        },
        {
            field: "deletePermission",
            headerName: translate("permissions.delete").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            cellClassName: "tableSwitchAlignCenter",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.deletePermission, disable: !hasEditPermission || role?.name == RoleEnum[RoleEnum.SuperAdmin], onChange: ($event) => handlePermissionChange(PermissionType[PermissionType.deletePermission], params.row.module) }, params.row.id)),
        },
    ];
    const { setValue, formState: { errors }, handleSubmit, } = useForm({});
    useEffect(() => {
        if (type) {
            dispatch(getRolePermissionByIdAsync(type));
            dispatch(getRoleByIdAsync(type));
        }
    }, [dispatch, type]);
    useEffect(() => {
        dispatch(resetFilter("permissions"));
    }, [dispatch]);
    useEffect(() => {
        if (role) {
            setValue("roleName", role.name);
        }
    }, [role]);
    useEffect(() => {
        if (rolePermission && rolePermission?.length > 0)
            setRolePermissions(rolePermission);
        else
            setRolePermissions(newRolePermissions());
    }, [rolePermission]);
    const handlePermissionChange = (permissionKey, module) => {
        const moduleIndex = rolePermissions?.findIndex((permission) => permission.module === module);
        if (moduleIndex !== -1 && rolePermissions !== undefined) {
            setRolePermissions((prevPermissions) => {
                const updatedPermissions = [...prevPermissions];
                const editPermission = PermissionType[PermissionType.editPermission];
                const createPermission = PermissionType[PermissionType.createPermission];
                const viewPermission = PermissionType[PermissionType.viewPermission];
                switch (permissionKey) {
                    case PermissionType[PermissionType.deletePermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                            [editPermission]: !prevPermissions[moduleIndex][permissionKey],
                            [createPermission]: !prevPermissions[moduleIndex][permissionKey],
                            [viewPermission]: module !== Module[Module.Dashboard]
                                ? !prevPermissions[moduleIndex][permissionKey]
                                : prevPermissions[moduleIndex][viewPermission],
                        };
                        break;
                    case PermissionType[PermissionType.editPermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                            [createPermission]: !prevPermissions[moduleIndex][permissionKey],
                            [viewPermission]: module !== Module[Module.Dashboard]
                                ? !prevPermissions[moduleIndex][permissionKey]
                                : prevPermissions[moduleIndex][viewPermission],
                        };
                        break;
                    case PermissionType[PermissionType.createPermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                            [viewPermission]: module !== Module[Module.Dashboard]
                                ? !prevPermissions[moduleIndex][permissionKey]
                                : prevPermissions[moduleIndex][viewPermission],
                        };
                        break;
                    case PermissionType[PermissionType.viewPermission]:
                        updatedPermissions[moduleIndex] = {
                            ...updatedPermissions[moduleIndex],
                            [permissionKey]: !prevPermissions[moduleIndex][permissionKey],
                        };
                        break;
                }
                return updatedPermissions;
            });
        }
    };
    const onSubmit = (credentials) => {
        credentials.roleId = type ?? "";
        credentials.roleClaims = rolePermissions;
        dispatch(updateRolePermissionsAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/permissions");
            }
        });
    };
    // const includeRoles = ['Admin', 'Board', 'School', 'Teacher', 'Supervisor', 'Student'];
    // const isDisabledRole = (roleName: string): boolean => {
    //   if(includeRoles.includes(roleName)){
    //     return true;
    //   }
    //   return !hasEditPermission;
    // }
    const onFilterModelChange = (model) => dispatch(setRolePermissionFilterModel(model));
    const onClearFilterModel = () => dispatch(setRolePermissionFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    return (_jsx(_Fragment, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "role-permission-form", noValidate: true, children: status == APIStatus.IDLE && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: `${role?.name} ${translate("permissions.permissions")}` }) }), _jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })) })] }), _jsxs(Box, { sx: {
                            marginTop: "1rem",
                            borderRadius: "12px",
                            backgroundColor: "#fff",
                            padding: "1rem",
                        }, children: [_jsx(Box, { sx: {
                                    marginBottom: "1rem",
                                } }), _jsxs("div", { style: { width: "100%" }, children: [_jsx(DataGrid, { componentsProps: {
                                            filterPanel: { sx: { maxWidth: "85vw" } },
                                        }, autoHeight: true, rows: rolePermissions, columns: columns, filterModel: rolePermissionFilterModel, onFilterModelChange: onFilterModelChange, getRowId: (row) => row.module, initialState: {
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                                },
                                            },
                                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !rolePermissions?.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" } }, "role-grid"), hasEditPermission &&
                                        role?.name != RoleEnum[RoleEnum.SuperAdmin] && (_jsx(Box, { marginTop: 1, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }) }) }))] })] })] })) }) }));
};
export default EditRolePermission;
