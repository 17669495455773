import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autocomplete, Box, Button, Grid, TextField, Typography, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { addThemeAsync } from "@application/features/theme/themeThunk";
import { useNavigate } from "react-router-dom";
import { getSeasonDropdownAsync } from "@application/features/season/seasonThunk";
import "./style.scss";
import { translate } from "@application/common/helpers/translate";
const AddTheme = () => {
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { seasonDropDownList } = useAppSelector((state) => state.season);
    useEffect(() => {
        dispatch(getSeasonDropdownAsync());
    }, [dispatch]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const { register, setError, setValue, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            seasonId: "",
            description: "",
            featureImage: undefined,
        },
    });
    const onSubmit = (credentials) => {
        if (selectedImage === null || selectedImage === undefined) {
            dispatch(addErrorNotification(translate("validations.fieldIsRequired").replace("{field}", translate("common.featureImage"))));
            return;
        }
        credentials.featureImage = selectedImage;
        credentials.seasonId = selectedSeason?.id ?? "";
        dispatch(addThemeAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/themes");
            }
        });
    };
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                setSelectedImage(selectedFile);
            }
            else {
                dispatch(addErrorNotification(translate("validations.fileNotImage")));
                return;
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("themes.theme")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs(Grid, { container: true, spacing: 2.5, justifyContent: "space-between", children: [_jsx(Grid, { item: true, lg: 3, md: 6, sm: 6, xs: 12, children: _jsxs("div", { className: "image-box theme", children: [_jsx("div", { className: "image-container", children: _jsx("img", { src: selectedImage ? URL.createObjectURL(selectedImage) : "", alt: "" }) }), _jsx("input", { type: "file", ref: fileInputRef, style: { display: "none" }, onChange: handleFileChange }), _jsx(Button, { className: "Button", variant: "outlined", color: "primary", sx: {
                                            width: "100%",
                                            borderTopLeftRadius: "0",
                                            borderTopRightRadius: "0",
                                        }, onClick: handleButtonClick, children: translate("cta.upload") }), _jsx(Typography, { variant: "h6", textAlign: "center", marginTop: 0.5, marginBottom: 1, children: translate("common.featureImage") }), _jsxs(Typography, { variant: "body1", textAlign: "center", children: [translate("themes.themeImagePreview"), _jsx("br", {}), translate("themes.themeImagePreviewSizeLimit")] })] }) }), _jsx(Grid, { item: true, lg: 8, sm: 6, md: 6, xs: 12, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "theme-form", noValidate: true, children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(TextField, { id: "name", label: translate("themes.themeName"), variant: "outlined", size: "small", placeholder: translate("themes.themeName"), type: "text", error: !!errors.name, helperText: errors.name?.message, fullWidth: true, ...register("name", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("themes.themeName")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedSeason, onChange: (event, newValue) => {
                                                    setValue("seasonId", newValue?.id ?? "");
                                                    setSelectedSeason(newValue);
                                                    if (newValue !== null && newValue !== undefined) {
                                                        setError("seasonId", {
                                                            type: "required",
                                                            message: undefined,
                                                        });
                                                    }
                                                }, options: seasonDropDownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("themes.season"), placeholder: translate("themes.season"), variant: "outlined", ...register("seasonId", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("themes.season")),
                                                        },
                                                    }), error: errors.seasonId && selectedSeason === null
                                                        ? true
                                                        : false, helperText: errors.seasonId?.message })) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", helperText: errors.description?.message, error: !!errors.description, fullWidth: true, ...register("description", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }) }), _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) })] }) }) })] }) })] }));
};
export default AddTheme;
