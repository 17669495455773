import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, Typography, useTheme } from "@mui/material";
const BoardDashboardNews = ({ newsData, }) => {
    const storageUrl = process.env.STORAGE_ACCOUNT;
    const theme = useTheme();
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
    };
    const newsImageStyles = {
        minHeight: 200,
        maxHeight: 200,
        height: 200,
        width: "100%",
        objectFit: "contain",
        objectPosition: "center center",
        borderRadius: 1,
    };
    return (_jsx("div", { children: _jsx(Slider, { ...sliderSettings, children: newsData &&
                newsData.map((news) => (_jsx(Box, { children: _jsxs(Grid, { item: true, container: true, spacing: 2, children: [_jsx(Grid, { item: true, md: 6, sm: 6, xs: 12, children: _jsx(Box, { component: "img", 
                                    // src={`data:image/png;base64,${news.picture}`}
                                    src: storageUrl?.replace("fileShoulebeReplace", news.picture), sx: newsImageStyles }) }), _jsx(Grid, { item: true, md: 6, sm: 6, xs: 12, children: _jsx("iframe", { src: news.videoLink, style: {
                                        width: "100%",
                                        minHeight: "200px",
                                        maxHeight: "200px",
                                        height: "200px",
                                        borderRadius: "12px",
                                    } }) }), _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { sx: {
                                            fontSize: 18,
                                            fontWeight: "600",
                                            color: theme.palette.primary.main,
                                        }, children: news.title }), _jsx(Typography, { variant: "body1", children: news.description })] })] }) }))) }) }));
};
export default BoardDashboardNews;
