import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from "@application/common/helpers/translate";
import { addGoalAsync } from "@application/features/goal/goalThunk";
import { useAppDispatch } from "@application/store/useStore";
import { Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const AddGoal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            description: "",
        },
    });
    const onSubmit = (credentials) => {
        dispatch(addGoalAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/goals");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("goals.goal")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "goal-form", noValidate: true, children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "goalName", label: translate("goals.goalName"), variant: "outlined", size: "small", placeholder: translate("goals.goalName"), type: "text", fullWidth: true, error: !!errors.name, ...register("name", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("goals.goalName")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.name })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 8, children: [_jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), type: "text", fullWidth: true, error: !!errors.description, ...register("description", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.description })] })] }) }), _jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) }) }) })] }) })] }));
};
export default AddGoal;
