import { APIStatus } from '@application/common/enums/api-status';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setThemeFilterModel } from '../theme/themeSlice';
import { setBoardFilterModel } from '../board/boardSlice';
import { setSchoolFilterModel } from '../school/schoolSlice';
import { setTeacherFilterModel } from '../teacher/teacherSlice';
import { setGoalFilterModel } from '../goal/goalSlice';
import { setSubGoalFilterModel } from '../sub-goal/subGoalSlice';
import { setCourseFilterModel } from '../course/courseSlice';
import { setVouchersFilterModel } from '../voucher/voucherSlice';
import { setNewsFilterModel } from '../news/newsSlice';
import { setFaqsFilterModel } from '../faq/faqSlice';
import { setAgreementFilterModel } from '../agreements/agreementSlice';
import { setUsersFilterModel } from '../user/userSlice';
import { setRolePermissionFilterModel } from '../role-permission/rolePermissionSlice';
// Define the async action if needed
export const resetFilter = createAsyncThunk('filterReset/resetFilter', async (routePath, { dispatch, getState }) => {
    const state = getState();
    const currentRoutePath = state.filterReset.currentRoutePath;
    if (currentRoutePath === 'boards' && !(routePath === 'boards')) {
        dispatch(setBoardFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'schools' && !(routePath === 'schools')) {
        dispatch(setSchoolFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'teachers' && !(routePath === 'teachers')) {
        dispatch(setTeacherFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'goals' && !(routePath === 'goals')) {
        dispatch(setGoalFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'sub-goals' && !(routePath === 'sub-goals')) {
        dispatch(setSubGoalFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'themes' && !(routePath === 'themes')) {
        dispatch(setThemeFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'lessons' && !(routePath === 'lessons')) {
        dispatch(setCourseFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'vouchers' && !(routePath === 'vouchers')) {
        dispatch(setVouchersFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'news' && !(routePath === 'news')) {
        dispatch(setNewsFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'faqs' && !(routePath === 'faqs')) {
        dispatch(setFaqsFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'agreements' && !(routePath === 'agreements')) {
        dispatch(setAgreementFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'users' && !(routePath === 'users')) {
        dispatch(setUsersFilterModel({ items: [] }));
    }
    else if (currentRoutePath === 'permissions' && !(routePath === 'permissions')) {
        dispatch(setRolePermissionFilterModel({ items: [] }));
    }
    return routePath;
});
const initialState = {
    status: APIStatus.IDLE,
    currentRoutePath: ''
};
export const filterResetSlice = createSlice({
    name: 'filterReset',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetFilter.pending, (state) => {
            state.status = APIStatus.PENDING;
        })
            .addCase(resetFilter.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.currentRoutePath = action.payload;
        })
            .addCase(resetFilter.rejected, (state) => {
            state.status = APIStatus.FAILED;
        });
    }
});
export const {} = filterResetSlice.actions;
export default filterResetSlice.reducer;
