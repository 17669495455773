import { wijzneusApi } from "./wijzneus-api";
const getSchools = () => {
    return wijzneusApi.get(`schools`);
};
const getSchool = (id) => {
    return wijzneusApi.get(`schools/${id}`);
};
const updateSchoolStatus = (request) => {
    return wijzneusApi.patch(`schools`, request);
};
const addSchool = (request) => {
    return wijzneusApi.post(`schools`, request);
};
const updateSchool = (request) => {
    return wijzneusApi.put(`schools`, request);
};
const getTeachersBySchoolId = (schoolId) => {
    return wijzneusApi.get(`schools/teachers?SchoolId=${schoolId}`);
};
const deleteSchool = (schoolId) => {
    return wijzneusApi.delete(`schools/${schoolId}/delete`);
};
const getEffectedSchoolResults = (request) => {
    return wijzneusApi.get(`schools/${request}/effected-data`);
};
const SchoolApi = {
    getSchools,
    getSchool,
    updateSchoolStatus,
    addSchool,
    updateSchool,
    getTeachersBySchoolId,
    deleteSchool,
    getEffectedSchoolResults
};
export default SchoolApi;
