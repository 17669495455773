import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery, } from "@mui/material";
import "./style.scss";
import { Add, DeleteForever, FilterAltOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import WNLongMenu from "@ui/components/wn-long-menu";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect, useState } from "react";
import { deleteSchoolAsync, getEffectedSchoolAsync, getSchoolsAsync, updateSchoolStatusAsync, } from "@application/features/school/schoolThunk";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { resetFilter } from "@application/features/common/filterResetSlice";
import { setDeleteOpenDialog, setEffectedSchool, setSchoolFilterModel } from "@application/features/school/schoolSlice";
import AlertDialog from "@ui/components/wn-dialog";
function Schools() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { schools, schoolFilterModel, isDeleteOpen, effectedRows } = useAppSelector((state) => state.school);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const [deletedUserId, setDeletedUserId] = useState();
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Schools]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const hasDeletePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.deletePermission]]
        : false;
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "name",
            headerName: translate("schools.schoolName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            hideable: false,
        },
        {
            field: "board",
            headerName: translate("boards.board").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 150 : undefined,
            valueGetter: (params) => params.row.board.label,
        },
        {
            field: "teachersCount",
            headerName: translate("teachers&Supervisors.teachers").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 150 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "agreementsCount",
            headerName: translate("agreements.agreements").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 215 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "lastAgreementDate",
            headerName: translate("schools.lastAgreement").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 253 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.lastAgreementDate !== null
                ? formateDate(params.row.lastAgreementDate, DateFormatConstants.DMY)
                : ""}`,
        },
        {
            field: "createdDate",
            headerName: translate("schools.createdDate").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 253 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.createdDate !== null
                ? formateDate(params.row.createdDate, DateFormatConstants.DMY)
                : ""}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 100 : undefined,
            filterable: false,
            hideable: false,
            hideSortIcons: false,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, disable: !params.row.board.isActive, onChange: ($event) => onSchoolStatusUpdate({
                    id: params.row.id,
                    status: $event,
                }) }, params.row.id)),
        },
        {
            field: "deleteUser",
            headerName: translate("account.delete").toUpperCase(),
            sortable: false,
            align: "center",
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            headerAlign: "center",
            hideable: false,
            filterable: false,
            hideSortIcons: true,
            renderCell: (params) => (_jsx(Tooltip, { title: translate("account.delete"), arrow: true, placement: "top", children: _jsx(IconButton, { size: "medium", color: "error", onClick: () => handleDeleteOpenDialog(params.row), children: _jsx(DeleteForever, {}) }) })),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            filterable: false,
            minWidth: 100,
            hideable: false,
            hideSortIcons: false,
            renderCell: (params) => (_jsx(WNLongMenu, { options: options, onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id)),
        },
    ];
    useEffect(() => {
        dispatch(getSchoolsAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(resetFilter("schools"));
    }, [dispatch]);
    const onSchoolStatusUpdate = (updateSchoolStatusDto) => {
        dispatch(updateSchoolStatusAsync(updateSchoolStatusDto));
    };
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "ViewTeachers":
                navigate(`/admin/schools/${row.id}/teachers`);
                break;
            default:
                break;
        }
    };
    const options = [
        ...(hasViewPermission || hasEditPermission
            ? [
                {
                    key: "Details",
                    label: translate("cta.details"),
                    route: "Details",
                    isActive: true,
                },
            ]
            : []),
        ...(hasViewPermission
            ? [
                {
                    key: "ViewTeachers",
                    label: translate("cta.viewTeachers"),
                    route: "ViewTeachers",
                    isActive: true,
                },
            ]
            : []),
    ];
    const onFilterModelChange = (model) => dispatch(setSchoolFilterModel(model));
    const onClearFilterModel = () => dispatch(setSchoolFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    const handleConfirmDeleteAction = async () => {
        if (deletedUserId) {
            const result = await dispatch(deleteSchoolAsync(deletedUserId));
            if (result.payload === true) {
                dispatch(getSchoolsAsync());
            }
        }
        handleDeleteCloseDialog();
    };
    const handleDeleteOpenDialog = (params) => {
        setDeletedUserId(params.id);
    };
    const handleDeleteCloseDialog = () => {
        setDeletedUserId("");
        dispatch(setEffectedSchool(undefined));
        dispatch(setDeleteOpenDialog(false));
    };
    useEffect(() => {
        if (deletedUserId && deletedUserId !== "") {
            dispatch(getEffectedSchoolAsync(deletedUserId));
        }
    }, [dispatch, deletedUserId]);
    useEffect(() => {
        if (effectedRows) {
            dispatch(setDeleteOpenDialog(true));
        }
    }, [dispatch, effectedRows]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("schools.schools") }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })), hasCreatePermission &&
                                (isxs ? (_jsx(IconButton, { size: "medium", onClick: () => navigate("/admin/schools/add"), children: _jsx(Add, { color: "primary", fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/schools/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") })))] })] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(DataGrid, { autoHeight: true, componentsProps: { filterPanel: { sx: { maxWidth: "85svw" } } }, rows: schools ?? [], 
                    //loading={status === APIStatus.PENDING}
                    columns: columns, filterModel: schoolFilterModel, onFilterModelChange: onFilterModelChange, initialState: {
                        pagination: {
                            paginationModel: {
                                pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                            },
                        },
                    }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: schools === undefined || schools?.length <= 0, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                        isActive: hasEditPermission ?? true,
                        "": (hasEditPermission || hasViewPermission) ?? true,
                        deleteUser: hasDeletePermission === true ? true : false
                    } }, "school-grid") }), _jsx(AlertDialog, { open: isDeleteOpen, onClose: handleDeleteCloseDialog, title: translate("deleteUserDialog.title"), content: translate("deleteUserDialog.content"), onConfirm: handleConfirmDeleteAction, onCancel: handleDeleteCloseDialog, confirmButtonText: translate("cta.proceed"), cancelButtonText: translate("cta.cancel"), isDeleted: true, description: _jsxs("div", { children: [_jsx("p", { children: translate("deleteUserDialog.followingData") }), effectedRows && effectedRows?.totalUsers > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.totalUsers"), " =", " ", effectedRows?.totalUsers] })), effectedRows && effectedRows?.totalCourses > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherCourses"), " =", " ", effectedRows?.totalCourses] })), effectedRows && effectedRows?.totalThemes > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherThemes"), " =", " ", effectedRows?.totalThemes] })), effectedRows && effectedRows?.totalVouchers > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherVouchers"), " =", " ", effectedRows?.totalVouchers] })), effectedRows && effectedRows?.totalAnnualPlan > 0 && (_jsxs("div", { children: [translate("deleteUserDialog.teacherAnnualPlans"), " =", " ", effectedRows?.totalAnnualPlan] }))] }) })] }));
}
export default Schools;
