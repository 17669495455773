import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from "@application/common/helpers/translate";
import { getBoardsDropdownAsync, getSchoolsDropdownAsync, } from "@application/features/board/boardThunk";
import { addTeacherAsync } from "@application/features/teacher/teacherThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const roleList = [
    { id: translate("teachers&Supervisors.teacher"), label: "Teacher" },
    { id: translate("teachers&Supervisors.supervisor"), label: "Supervisor" },
];
const AddTeacher = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const { schoolDropdownList } = useAppSelector((state) => state.board);
    const { register, setError, setValue, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            email: "",
            firstName: "",
            lastName: "",
            boardId: "",
            schoolId: "",
            roleName: "",
        },
    });
    useEffect(() => {
        dispatch(getSchoolsDropdownAsync(false));
        dispatch(getBoardsDropdownAsync(false));
    }, [dispatch]);
    const onSubmit = (credentials) => {
        credentials.boardId = selectedSchool?.boardId ?? "";
        credentials.schoolId = selectedSchool?.id ?? "";
        credentials.roleName = selectedRole?.label ?? "";
        dispatch(addTeacherAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/teachers");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("teachers&Supervisors.teacher&Supervisor")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "teacher-form", noValidate: true, children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedRole, onChange: (event, newValue) => {
                                                    setValue("roleName", newValue?.id ?? "");
                                                    setSelectedRole(newValue);
                                                    if (newValue !== null && newValue !== undefined) {
                                                        setError("roleName", {
                                                            type: "required",
                                                            message: undefined,
                                                        });
                                                    }
                                                }, options: roleList, getOptionLabel: (option) => option.id, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("teachers&Supervisors.roleType"), placeholder: translate("teachers&Supervisors.roleType"), variant: "outlined", ...register("roleName", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("teachers&Supervisors.roleType")),
                                                        },
                                                    }), error: errors.roleName && selectedRole === null ? true : false })) }), _jsx(WnValidation, { fieldError: errors.roleName })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "firstName", label: translate("account.firstName"), variant: "outlined", size: "small", placeholder: translate("account.firstName"), type: "text", error: !!errors.firstName, fullWidth: true, ...register("firstName", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("account.firstName")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.firstName })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "lastName", label: translate("account.lastName"), variant: "outlined", size: "small", placeholder: translate("account.lastName"), fullWidth: true, error: !!errors.lastName, ...register("lastName", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("account.lastName")),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.lastName })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "email", label: translate("account.email"), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), type: "email", fullWidth: true, error: !!errors.email, ...register("email", {
                                                    required: {
                                                        value: true,
                                                        message: translate("validations.fieldIsRequired").replace("{field}", translate("account.email")),
                                                    },
                                                    pattern: {
                                                        value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: translate("validations.invalidEmailAddress"),
                                                    },
                                                }) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedSchool, onChange: (event, newValue) => {
                                                    setValue("schoolId", newValue?.id ?? "");
                                                    setSelectedSchool(newValue);
                                                    if (newValue !== null && newValue !== undefined) {
                                                        setError("schoolId", {
                                                            type: "required",
                                                            message: undefined,
                                                        });
                                                    }
                                                }, groupBy: (option) => option.boardName, options: schoolDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("schools.school"), placeholder: translate("schools.school"), variant: "outlined", ...register("schoolId", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("schools.school")),
                                                        },
                                                    }), error: errors.schoolId && selectedSchool === null
                                                        ? true
                                                        : false })) }), _jsx(WnValidation, { fieldError: errors.schoolId })] })] }) }), _jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) }) }) })] }) })] }));
};
export default AddTeacher;
