import { getErrorPayload } from "../../common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TeacherApi from "@infrastructure/teacher-api";
import { addSuccessNotification } from "../common/notificationSlice";
import { translate } from "@application/common/helpers/translate";
export const getTeachersAsync = createAsyncThunk("teacher/getTeachers", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeachers();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getTeacherByIdAsync = createAsyncThunk("teacher/getTeacher", async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await TeacherApi.getTeacher(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const addTeacherAsync = createAsyncThunk("teacher/addTeacher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.addTeacher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.addModuleSuccess").replace("{module}", translate("teachers&Supervisors.teacher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateTeacherAsync = createAsyncThunk("teacher/updateTeacher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.updateTeacher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("teachers&Supervisors.teacher"))));
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableDisableTeacherAsync = createAsyncThunk("teacher/enableDisableTeacher", async (credentials, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await TeacherApi.enableDisableTeacher(credentials);
        if (response.status === 200) {
            dispatch(addSuccessNotification(translate("cta.updateModuleSuccess").replace("{module}", translate("teachers&Supervisors.teacher"))));
            dispatch(getTeachersAsync());
            return fulfillWithValue(true);
        }
        else {
            return fulfillWithValue(false);
        }
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
