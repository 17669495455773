import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from "@application/common/enums/api-status";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { getSchoolsDropdownAsync } from "@application/features/board/boardThunk";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { getTeacherByIdAsync, updateTeacherAsync, } from "@application/features/teacher/teacherThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
const roleList = [
    { id: translate("teachers&Supervisors.teacher"), label: "Teacher" },
    { id: translate("teachers&Supervisors.supervisor"), label: "Supervisor" },
];
const EditTeacher = () => {
    const { type } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const { boardDropdownList, schoolDropdownList, status: schoolDropdownStatus, } = useAppSelector((state) => state.board);
    const { teacher, status } = useAppSelector((state) => state.teacher);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module["Teachers & Supervisors"]]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const { register, setError, setValue, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            boardId: "",
            schoolId: "",
            roleName: "",
        },
    });
    useEffect(() => {
        dispatch(getSchoolsDropdownAsync(false));
    }, [dispatch]);
    useEffect(() => {
        if (type) {
            dispatch(getTeacherByIdAsync(type));
        }
    }, [dispatch, type]);
    useEffect(() => {
        if (teacher) {
            setValue("boardId", teacher.board.id);
            dispatch(getSchoolsDropdownAsync(false)).then((res) => {
                if (Array.isArray(res.payload)) {
                    setSelectedSchool({
                        ...(res.payload?.filter((x) => x.id.toLocaleLowerCase() === teacher.school.id)[0] ?? null),
                    });
                    setValue("schoolId", teacher.school.id);
                }
                else {
                    dispatch(addErrorNotification("Error fetching schools."));
                }
            });
            setValue("roleName", teacher.role.id);
            setSelectedRole(roleList?.filter((x) => x.label === teacher.role.name)[0] ?? null);
            setValue("firstName", teacher.firstName);
            setValue("lastName", teacher.lastName);
            setValue("email", teacher.email);
        }
    }, [boardDropdownList, dispatch, setValue, teacher]);
    const onSubmit = (credentials) => {
        credentials.id = type ?? "";
        credentials.boardId = selectedSchool?.boardId ?? "";
        credentials.schoolId = selectedSchool?.id ?? "";
        credentials.roleName = selectedRole?.label ?? "";
        dispatch(updateTeacherAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/teachers");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                            ? translate("cta.editModule").replace("{module}", translate("teachers&Supervisors.teacher&Supervisor"))
                            : translate("cta.viewModule").replace("{module}", translate("teachers&Supervisors.teacher&Supervisor")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), id: "teacher-form", noValidate: true, children: status == APIStatus.IDLE &&
                        schoolDropdownStatus == APIStatus.IDLE && (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedRole, disabled: !hasEditPermission, onChange: (event, newValue) => {
                                                        setValue("roleName", newValue?.id ?? "");
                                                        setSelectedRole(newValue);
                                                        if (newValue !== null && newValue !== undefined) {
                                                            setError("roleName", {
                                                                type: "required",
                                                                message: undefined,
                                                            });
                                                        }
                                                    }, options: roleList, getOptionLabel: (option) => option.id, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("teachers&Supervisors.roleType"), placeholder: translate("teachers&Supervisors.roleType"), variant: "outlined", ...register("roleName", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("teachers&Supervisors.roleType")),
                                                            },
                                                        }), error: errors.roleName && selectedRole === null
                                                            ? true
                                                            : false })) }), _jsx(WnValidation, { fieldError: errors.roleName })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "firstName", label: translate("account.firstName"), variant: "outlined", size: "small", placeholder: translate("account.firstName"), type: "text", error: !!errors.firstName, fullWidth: true, disabled: !hasEditPermission, ...register("firstName", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.firstName")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }), InputLabelProps: { shrink: true } }), _jsx(WnValidation, { fieldError: errors.firstName })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "lastName", label: translate("account.lastName"), variant: "outlined", size: "small", placeholder: translate("account.lastName"), fullWidth: true, disabled: !hasEditPermission, error: !!errors.lastName, ...register("lastName", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.lastName")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }), InputLabelProps: { shrink: true } }), _jsx(WnValidation, { fieldError: errors.lastName })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(TextField, { id: "email", label: translate("account.email"), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), type: "email", fullWidth: true, disabled: !hasEditPermission, InputLabelProps: {
                                                        shrink: true,
                                                    }, error: !!errors.email, ...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.email")),
                                                        },
                                                        pattern: {
                                                            value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translate("validations.invalidEmailAddress"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsxs(Grid, { item: true, xs: 12, md: 4, sm: 6, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedSchool, disabled: !hasEditPermission, onChange: (event, newValue) => {
                                                        setValue("schoolId", newValue?.id ?? "");
                                                        setSelectedSchool(newValue);
                                                        if (newValue !== null && newValue !== undefined) {
                                                            setError("schoolId", {
                                                                type: "required",
                                                                message: undefined,
                                                            });
                                                        }
                                                    }, groupBy: (option) => option.boardName, options: schoolDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("schools.school"), placeholder: translate("schools.school"), variant: "outlined", ...register("schoolId", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("schools.school")),
                                                            },
                                                        }), error: errors.schoolId && selectedSchool === null
                                                            ? true
                                                            : false })) }), _jsx(WnValidation, { fieldError: errors.schoolId })] })] }) }), hasEditPermission && (_jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.saveChanges") }) }) }) }))] })) }) })] }));
};
export default EditTeacher;
