import { wijzneusApi } from "./wijzneus-api";
const getUsers = () => {
    return wijzneusApi.get(`users`);
};
const getUser = (request) => {
    return wijzneusApi.get(`users/${request}`);
};
const addUser = (request) => {
    return wijzneusApi.post(`users`, request);
};
const updateUser = (request) => {
    return wijzneusApi.put(`users`, request);
};
const updateUserPassword = (request) => {
    return wijzneusApi.patch(`users/UpdateUserPassword`, request);
};
const enableDisableUser = (request) => {
    return wijzneusApi.patch(`users`, request);
};
const getEffectedResults = (request) => {
    return wijzneusApi.get(`users/${request}/teachers`);
};
const deleteTeacherUser = (teacherId) => {
    return wijzneusApi.delete(`users/${teacherId}`);
};
const UserApi = {
    getUsers,
    getUser,
    addUser,
    updateUser,
    enableDisableUser,
    updateUserPassword,
    getEffectedResults,
    deleteTeacherUser
};
export default UserApi;
