import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CourseInstructionCategory, CourseInstructionCategoryStatusMap, } from "@application/common/enums/course-instruction-category";
import { CourseInstructionType } from "@application/common/enums/course-instruction-type";
import { CourseItemCategory, CourseItemCategoryStatusMap, } from "@application/common/enums/course-item-category";
import { CourseItemType } from "@application/common/enums/course-item-type";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { addErrorNotification } from "@application/features/common/notificationSlice";
import { getCourseByIdAsync, getDownloadAndViewCourseAsync, updateCourseAsync, } from "@application/features/course/courseThunk";
import { getSubGoalDropdownAsync } from "@application/features/sub-goal/subGoalThunk";
import { getThemeDropdownListAsync } from "@application/features/theme/themeThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Edit, FileUpload } from "@mui/icons-material";
import { Autocomplete, Box, Button, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tab, Tabs, TextField, Typography, useTheme, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { translate } from "@application/common/helpers/translate";
const EditLesson = () => {
    const fileInputRef = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { type } = useParams();
    const { course } = useAppSelector((state) => state.course || {}) || {};
    const [loading, setLoading] = useState(false);
    const { themeDropdownList } = useAppSelector((state) => state.theme);
    const { subGoalDropdownList } = useAppSelector((state) => state.subGoal);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Lessons]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getSubGoalDropdownAsync());
        dispatch(getThemeDropdownListAsync());
    }, [dispatch]);
    useEffect(() => {
        fetchDataAndDispatch();
    }, [dispatch, type]);
    const fetchDataAndDispatch = async () => {
        try {
            // Dispatch an action
            if (type) {
                await dispatch(getCourseByIdAsync(type));
                setLoading(true);
            }
            // The following code will run after the dispatch is completed
            console.log("Dispatch completed!");
            // Your additional logic here
        }
        catch (error) {
            console.error("Error during dispatch:", error);
        }
    };
    const [selectedImage, setSelectedImage] = useState();
    const [imageFile, setImageFile] = useState(undefined);
    const { register, watch, setValue, control, getValues, formState: { errors }, handleSubmit, reset, clearErrors, } = useForm({
        defaultValues: undefined,
    });
    const [tabErrors, setTabErrors] = useState([false, false]);
    const { fields, append, remove } = useFieldArray({
        control,
        name: "courseItems",
    });
    const { fields: courseInstructions, append: appendCourseInstruction, remove: removeCourseInstruction, } = useFieldArray({
        control,
        name: "courseInstructions",
    });
    // useEffect(() => {
    //   reset({
    //     courseItems: [],
    //   });
    //   reset({
    //     courseInstructions: [],
    //   });
    //   setValue("courseItems", []);
    //   setValue("courseInstructions", []);
    // }, [reset, setValue]);
    const onSubmit = (credentials) => {
        if (errors.courseItems || errors.courseInstructions) {
            return;
        }
        if (credentials.courseInstructions.length > 10) {
            dispatch(addErrorNotification(translate("validations.lessons.courseInstructionsLimit")));
            return;
        }
        if (credentials.courseInstructions) {
            const checkDuplicateCategory = credentials.courseInstructions.map((x) => Number(x.courseInstructionCategory));
            if (hasDuplicates(checkDuplicateCategory)) {
                dispatch(addErrorNotification(translate("validations.lessons.duplicateCategoriesNotAllowed")));
                return;
            }
        }
        if (credentials.courseItems.length > 9) {
            dispatch(addErrorNotification(translate("validations.lessons.courseItemsLimit")));
            return;
        }
        if (selectedImage != null && selectedImage !== undefined) {
            credentials.featureImage = selectedImage;
        }
        credentials.id = type ?? "";
        dispatch(updateCourseAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/lessons");
            }
        });
    };
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type.startsWith("image/")) {
                setSelectedImage(selectedFile);
            }
            else {
                dispatch(addErrorNotification(translate("validations.fileNotImage")));
                return;
            }
        }
    };
    const handleCourseItemFileChange = (index, e) => {
        setValue(`courseItems.${index}.fileName`, undefined);
        const file = e.target.files != null ? e.target.files[0] : null;
        const isValidFile = validateFile(file, index);
        if (!isValidFile) {
            setValue(`courseItems.${index}.file`, file);
            return;
        }
        clearErrors(`courseItems.${index}.file`);
        clearErrors(`courseItems.${index}.link`);
        setValue(`courseItems.${index}.file`, file);
    };
    const validateFile = (file, index) => {
        if (getValues(`courseItems.${index}.fileName`) === undefined &&
            file === undefined) {
            return translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseItems.courseItemFile"));
        }
        if (file === undefined)
            return true;
        const courseItemType = getValues(`courseItems.${index}.courseItemType`);
        if (courseItemType === CourseItemType.UploadAttachment) {
            const allowedFileTypes = ["application/pdf"];
            if (!file) {
                return translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseItems.courseItemFile"));
            }
            if (!allowedFileTypes.includes(file.type)) {
                return translate("validations.onlyPDFAllowed");
            }
        }
        return true;
    };
    const courseCategoryTypeList = [
        {
            id: Number(CourseItemCategory.Video).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Video),
        },
        {
            id: Number(CourseItemCategory.Audio).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Audio),
        },
        {
            id: Number(CourseItemCategory.Picture).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Picture),
        },
        {
            id: Number(CourseItemCategory.Miscellaneous).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Miscellaneous),
        },
        {
            id: Number(CourseItemCategory.ReadingSheet).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.ReadingSheet),
        },
        {
            id: Number(CourseItemCategory.WorkSheet).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.WorkSheet),
        },
        {
            id: Number(CourseItemCategory.Craft).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Craft),
        },
        {
            id: Number(CourseItemCategory.Language).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Language),
        },
        {
            id: Number(CourseItemCategory.Calculate).toString(),
            label: CourseItemCategoryStatusMap.get(CourseItemCategory.Calculate),
        },
    ];
    const courseInstructionCategoryTypeList = [
        {
            id: Number(CourseInstructionCategory.Instruction).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Instruction),
        },
        {
            id: Number(CourseInstructionCategory.ReadingSheet).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.ReadingSheet),
        },
        {
            id: Number(CourseInstructionCategory.WorkSheet).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.WorkSheet),
        },
        {
            id: Number(CourseInstructionCategory.Craft).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Craft),
        },
        {
            id: Number(CourseInstructionCategory.Language).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Language),
        },
        {
            id: Number(CourseInstructionCategory.Calculate).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Calculate),
        },
        {
            id: Number(CourseInstructionCategory.Video).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Video),
        },
        {
            id: Number(CourseInstructionCategory.Audio).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Audio),
        },
        {
            id: Number(CourseInstructionCategory.Picture).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Picture),
        },
        {
            id: Number(CourseInstructionCategory.Miscellaneous).toString(),
            label: CourseInstructionCategoryStatusMap.get(CourseInstructionCategory.Miscellaneous),
        }
    ];
    const onAddNewSection = () => {
        append({
            id: "",
            name: "",
            description: "",
            courseItemType: 0,
            link: "",
            file: undefined,
            courseItemCategory: undefined,
        });
    };
    const onAddNewInstructionSection = () => {
        appendCourseInstruction({
            courseInstructionType: 1,
            courseInstructionCategory: undefined,
            link: undefined,
            file: undefined,
        });
    };
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        const updatedTabErrors = [...tabErrors];
        updatedTabErrors[tabValue] = false;
        setTabErrors(updatedTabErrors);
        setTabValue(newValue);
    };
    useEffect(() => {
        if (type && course && loading) {
            setValue("id", type);
            setValue("name", course.name);
            setValue("introduction", course.introduction);
            setValue("goalIds", course.goalIds);
            setValue("subGoalIds", course.subGoalIds);
            setValue("themeId", course.themeId.toLocaleLowerCase());
            setValue("courseItems", course.courseItems);
            if (course && course.courseItems && course.courseItems.length > 0) {
                course.courseItems.forEach((value, index) => {
                    setValue(`courseItems.${index}.id`, value.id);
                    setValue(`courseItems.${index}.name`, value.name);
                    setValue(`courseItems.${index}.description`, value.description);
                    setValue(`courseItems.${index}.courseItemType`, value.courseItemType);
                    if (value.courseItemType == CourseItemType.ExternalLink) {
                        setValue(`courseItems.${index}.link`, value.link);
                    }
                    if (value.courseItemType == CourseItemType.UploadAttachment) {
                        const fileNameValue = value.link?.split("/")[1];
                        setValue(`courseItems.${index}.fileName`, fileNameValue ?? "");
                    }
                });
            }
            setValue("courseInstructions", course.courseInstructions);
            if (course &&
                course.courseInstructions &&
                course.courseInstructions.length > 0) {
                course.courseInstructions.forEach((value, index) => {
                    setValue(`courseInstructions.${index}.id`, value.id);
                    setValue(`courseInstructions.${index}.courseInstructionType`, value.courseInstructionType);
                    setValue(`courseInstructions.${index}.courseInstructionCategory`, value.courseInstructionCategory);
                    if (value.courseInstructionType == CourseInstructionType.ExternalLink) {
                        setValue(`courseInstructions.${index}.link`, value.link);
                    }
                    if (value.courseInstructionType ==
                        CourseInstructionType.UploadAttachment) {
                        const fileNameValue = value.link?.split("/")[1];
                        setValue(`courseInstructions.${index}.fileName`, fileNameValue ?? "");
                    }
                });
            }
        }
    }, [type, course, loading, setValue]);
    useEffect(() => {
        if (course && loading) {
            const fileName = course.featuredImage.split("/")[1];
            dispatch(getDownloadAndViewCourseAsync(fileName)).then((res) => {
                if (res.payload !== null &&
                    res.payload !== undefined &&
                    res.payload !== "") {
                    const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                    setImageFile(base64Data);
                }
            });
        }
    }, [dispatch, course, loading]);
    const validateInstructionFile = (file, index) => {
        if (getValues(`courseInstructions.${index}.fileName`) === undefined &&
            file === undefined) {
            return translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseInstructions.courseInstructionFile"));
        }
        if (file === undefined)
            return true;
        const courseInstructionType = getValues(`courseInstructions.${index}.courseInstructionType`);
        if (courseInstructionType === CourseInstructionType.UploadAttachment) {
            const allowedFileTypes = ["application/pdf"];
            if (!file) {
                return translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseInstructions.courseInstructionFile"));
            }
            if (!allowedFileTypes.includes(file.type)) {
                return translate("validations.onlyPDFAllowed");
            }
        }
        return true;
    };
    const handleCourseInstructionFileChange = (index, e) => {
        const file = e.target.files != null ? e.target.files[0] : null;
        if (file) {
            setValue(`courseInstructions.${index}.fileName`, undefined);
        }
        const isValidFile = validateInstructionFile(file, index);
        if (!isValidFile) {
            setValue(`courseInstructions.${index}.file`, undefined);
            e.target.value = null;
            return;
        }
        clearErrors(`courseInstructions.${index}.file`);
        setValue(`courseInstructions.${index}.file`, file);
    };
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
        return (_jsx("div", { role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}`, ...other, children: value === index && (_jsx(Box, { sx: { py: 2 }, children: _jsx(Typography, { children: children }) })) }));
    }
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };
    const boxShadowColor = `rgba(${hexToRgb(theme.palette.primary.main)}, 0.1)`;
    const tabBoxStyle = {
        boxShadow: `0px 7px 47px 0px ${boxShadowColor}`,
        p: 2,
        pb: 4,
        backgroundColor: theme.palette.common.white,
        borderRadius: 1,
    };
    const hasDuplicates = (arr) => {
        const uniqueValues = new Set();
        for (const value of arr) {
            if (uniqueValues.has(value)) {
                return true;
            }
            uniqueValues.add(value);
        }
        return false;
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: hasEditPermission
                            ? translate("cta.editModule").replace("{module}", translate("lessons.lesson"))
                            : translate("cta.viewModule").replace("{module}", translate("lessons.lesson")) }) }) }), course && (_jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs(Grid, { container: true, spacing: 2.5, justifyContent: "space-between", children: [_jsx(Grid, { item: true, lg: 2, md: 4, xs: 12, children: _jsxs("div", { className: "image-box course", children: [_jsx("div", { className: "image-container", children: _jsx("img", { src: selectedImage
                                                ? URL.createObjectURL(selectedImage)
                                                : imageFile
                                                    ? imageFile
                                                    : "", alt: "" }) }), _jsx("input", { type: "file", ref: fileInputRef, style: { display: "none" }, onChange: handleFileChange }), hasEditPermission && (_jsx(Button, { className: "Button", variant: "outlined", color: "primary", sx: {
                                            width: "100%",
                                            borderTopLeftRadius: "0",
                                            borderTopRightRadius: "0",
                                        }, onClick: handleButtonClick, children: translate("cta.upload") })), _jsx(Typography, { variant: "h6", textAlign: "center", marginTop: 0.5, marginBottom: 1, children: translate("common.featureImage") }), hasEditPermission && (_jsxs(Typography, { variant: "body1", textAlign: "center", children: [translate("lessons.lessonImageDimension"), _jsx("br", {}), translate("lessons.lessonImageDimensionParameters")] }))] }) }), _jsx(Grid, { item: true, lg: 9, md: 8, xs: 12, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "course-form", noValidate: true, children: [_jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(TextField, { id: "name", label: translate("lessons.lessonName"), variant: "outlined", size: "small", placeholder: translate("lessons.lessonName"), type: "text", InputLabelProps: { shrink: true }, error: !!errors.name, helperText: errors.name?.message, fullWidth: true, disabled: !hasEditPermission, ...register("name", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.lessonName")),
                                                        },
                                                        maxLength: {
                                                            value: 20,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "20"),
                                                        },
                                                    }) }) }), _jsx(Grid, { item: true, lg: 6, xs: 12, children: subGoalDropdownList && (_jsx(Controller, { name: "subGoalIds", control: control, rules: {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("subGoals.subGoals")),
                                                        },
                                                    }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", multiple: true, filterSelectedOptions: true, options: subGoalDropdownList, value: value ?? course.subGoalIds, isOptionEqualToValue: (option, value) => option.id === value.id, groupBy: (option) => option.goalName, getOptionLabel: (option) => option.label, onChange: (_, value) => {
                                                            onChange(value ?? []);
                                                        }, disabled: !hasEditPermission, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("subGoals.subGoals"), placeholder: translate("subGoals.subGoals"), error: errors.subGoalIds ? true : false, helperText: errors.subGoalIds?.message })) })) })) }), _jsx(Grid, { item: true, lg: 6, xs: 12, children: themeDropdownList && (_jsx(Controller, { name: "themeId", control: control, rules: {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("themes.theme")),
                                                        },
                                                    }, render: ({ field: { onChange, value } }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: themeDropdownList, value: themeDropdownList.find((option) => option.id === value) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                            onChange(value?.id ?? "");
                                                        }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("themes.theme"), placeholder: translate("themes.theme"), error: !!errors.themeId, helperText: errors.themeId?.message })) })) })) }), _jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(TextField, { id: "introduction", label: translate("lessons.introduction"), variant: "outlined", size: "small", placeholder: translate("lessons.introduction"), type: "text", InputLabelProps: { shrink: true }, helperText: errors.introduction?.message, error: !!errors.introduction, fullWidth: true, disabled: !hasEditPermission, ...register("introduction", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.introduction")),
                                                        },
                                                    }) }) })] }), _jsxs(Box, { sx: { width: "100%" }, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: _jsxs(Tabs, { value: tabValue, onChange: handleChange, "aria-label": "basic tabs example", children: [_jsx(Tab, { icon: errors.courseItems && (_jsx(Icon, { fontSize: 24, icon: "fluent:error-circle-24-regular", color: `${theme.palette.error.main}` })), iconPosition: errors.courseItems ? "start" : undefined, label: translate("lessons.courseItems.courseItems"), ...a11yProps(0) }), _jsx(Tab, { icon: errors.courseInstructions && (_jsx(Icon, { fontSize: 24, icon: "fluent:error-circle-24-regular", color: `${theme.palette.error.main}` })), iconPosition: errors.courseInstructions ? "start" : undefined, label: translate("lessons.courseInstructions.courseInstructions"), ...a11yProps(1) })] }) }), _jsx("div", { style: { display: `${tabValue === 1 ? "none" : "block"}` }, children: _jsxs(Box, { sx: tabBoxStyle, children: [fields.map((item, index) => (_jsx("div", { children: _jsxs(Grid, { item: true, xs: 12, children: [_jsxs(Box, { sx: {
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }, mt: 1, children: [_jsxs(Typography, { variant: "h5", children: [translate("lessons.courseItems.section"), " ", index + 1] }), hasEditPermission && fields.length > 1 && (_jsx(IconButton, { onClick: () => remove(index), color: "error", children: _jsx("span", { className: "material-symbols-rounded", children: "remove" }) }))] }, `header-box-${index}`), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(TextField, { id: "title", label: translate("lessons.courseItems.name"), variant: "outlined", size: "small", placeholder: translate("lessons.courseItems.name"), error: errors.courseItems &&
                                                                                        !!errors.courseItems[index]?.name, helperText: errors.courseItems &&
                                                                                        errors.courseItems[index]?.name?.message, fullWidth: true, disabled: !hasEditPermission, InputLabelProps: { shrink: true }, ...register(`courseItems.${index}.name`, {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseItems.name")),
                                                                                        },
                                                                                        maxLength: {
                                                                                            value: 12,
                                                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "12"),
                                                                                        },
                                                                                    }) }) }, `title-grid-${index}`), _jsx(Grid, { item: true, lg: 6, xs: 12, children: courseCategoryTypeList && (_jsx(Controller, { name: `courseItems.${index}.courseItemCategory`, control: control, rules: {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseItems.courseItemCategory")),
                                                                                        },
                                                                                    }, render: ({ field: { onChange, value }, }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: courseCategoryTypeList, value: courseCategoryTypeList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, disabled: !hasEditPermission, onChange: (_, value) => {
                                                                                            onChange(value
                                                                                                ? Number(value?.id ?? undefined)
                                                                                                : null);
                                                                                        }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("lessons.courseItems.courseItemCategory"), placeholder: translate("lessons.courseItems.courseItemCategory"), error: errors.courseItems &&
                                                                                                !!errors.courseItems[index]
                                                                                                    ?.courseItemCategory, helperText: errors.courseItems &&
                                                                                                errors.courseItems[index]
                                                                                                    ?.courseItemCategory?.message })) })) })) }, `title-category-${index}`), _jsx(Grid, { item: true, lg: 12, xs: 12, children: _jsx(TextField, { id: "description", label: translate("common.description"), variant: "outlined", size: "small", placeholder: translate("common.description"), error: errors.courseItems &&
                                                                                        !!errors.courseItems[index]?.description, helperText: errors.courseItems &&
                                                                                        errors.courseItems[index]?.description
                                                                                            ?.message, fullWidth: true, disabled: !hasEditPermission, InputLabelProps: { shrink: true }, ...register(`courseItems.${index}.description`, {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("common.description")),
                                                                                        },
                                                                                    }) }) }, `description-grid-${index}`), _jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(FormControl, { children: _jsxs(RadioGroup, { "aria-labelledby": `demo-radio-buttons-group-label`, value: getValues(`courseItems.${index}.courseItemType`), onChange: ($event) => {
                                                                                            clearErrors(`courseItems.${index}.link`);
                                                                                            clearErrors(`courseItems.${index}.file`);
                                                                                            clearErrors(`courseItems.${index}.fileName`);
                                                                                            setValue(`courseItems.${index}.link`, undefined);
                                                                                            setValue(`courseItems.${index}.file`, undefined);
                                                                                            setValue(`courseItems.${index}.fileName`, undefined);
                                                                                            setValue(`courseItems.${index}.courseItemType`, Number($event.target.value));
                                                                                            // if (
                                                                                            //   item.courseItemType ==
                                                                                            //   CourseItemType.UploadAttachment
                                                                                            // ) {
                                                                                            //   setValue(
                                                                                            //     `courseItems.${index}.link`,
                                                                                            //     undefined
                                                                                            //   );
                                                                                            //   setValue(
                                                                                            //     `courseItems.${index}.courseItemType`,
                                                                                            //     Number($event.target.value)
                                                                                            //   );
                                                                                            // } else {
                                                                                            //   setValue(
                                                                                            //     `courseItems.${index}.file`,
                                                                                            //     undefined
                                                                                            //   );
                                                                                            //   setValue(
                                                                                            //     `courseItems.${index}.courseItemType`,
                                                                                            //     Number($event.target.value)
                                                                                            //   );
                                                                                            // }
                                                                                        }, children: [_jsx(FormControlLabel, { value: CourseItemType.ExternalLink, control: _jsx(Radio, {}), label: translate("cta.externalLink"), disabled: !hasEditPermission }), _jsx(FormControlLabel, { value: CourseItemType.UploadAttachment, control: _jsx(Radio, {}), label: translate("cta.uploadAttachment"), disabled: !hasEditPermission })] }) }, `courseItems.${index}.courseItemType`) }, `courseItemType-grid-${index}`), _jsx(Grid, { item: true, lg: 6, xs: 12, md: 6, children: watch(`courseItems.${index}.courseItemType`) ==
                                                                                    CourseItemType.ExternalLink ? (_jsx(TextField, { id: `courseItems.${index}.link`, label: translate("lessons.courseItems.link"), variant: "outlined", size: "small", placeholder: translate("lessons.courseItems.link"), InputLabelProps: { shrink: true }, disabled: !hasEditPermission, error: errors.courseItems &&
                                                                                        !!errors.courseItems[index]?.link, helperText: errors.courseItems &&
                                                                                        errors.courseItems[index]?.link?.message, fullWidth: true, ...register(`courseItems.${index}.link`, {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseItems.link")),
                                                                                        },
                                                                                        pattern: {
                                                                                            value: /^(http|https):\/\/[^ "]+$/,
                                                                                            message: translate("validations.invalidUrl"),
                                                                                        },
                                                                                    }) })) : (_jsxs(_Fragment, { children: [_jsx(Controller, { name: `courseItems.${index}.file`, control: control, rules: {
                                                                                                validate: (value) => validateFile(value, index),
                                                                                            }, render: ({ field }) => (_jsx(_Fragment, { children: _jsxs(Button, { component: "label", className: "Button", variant: "outlined", color: "primary", fullWidth: true, disabled: !hasEditPermission, endIcon: watch(`courseItems.${index}.fileName`) != undefined ||
                                                                                                        watch(`courseItems.${index}.file`) != undefined ? (_jsx(Edit, {})) : (_jsx(FileUpload, {})), children: [_jsx(Typography, { variant: "body1", sx: {
                                                                                                                whiteSpace: "normal",
                                                                                                                flex: "1 1 auto",
                                                                                                                wordBreak: "break-all",
                                                                                                            }, children: watch(`courseItems.${index}.fileName`) != undefined
                                                                                                                ? watch(`courseItems.${index}.fileName`)
                                                                                                                : watch(`courseItems.${index}.file`) == undefined
                                                                                                                    ? translate("cta.uploadFile")
                                                                                                                    : watch(`courseItems.${index}.file`).name }), _jsx("input", { id: index.toString(), type: "file", name: index.toString(), onChange: (e) => handleCourseItemFileChange(index, e), style: { display: "none" } })] }) })) }), errors.courseItems &&
                                                                                            errors.courseItems[index]?.file && (_jsx(WnValidation, { fieldError: errors.courseItems[index]?.file }))] })) }, `courseItemType-value-grid-${index}`)] }, `item-grid-container-${index}`)] }, `main-grid-${index}`) }, `main-div-${index}`))), hasEditPermission && (_jsx(Grid, { item: true, xs: 12, marginTop: fields.length > 0 ? 0 : 3, textAlign: "right", marginBottom: 2, children: _jsx(Button, { sx: { mt: 2 }, className: "Button", onClick: () => onAddNewSection(), variant: "outlined", disableElevation: true, color: "info", children: translate("cta.addNewSectionCourseItem") }) }))] }) }), _jsx("div", { style: { display: `${tabValue === 0 ? "none" : "block"}` }, children: _jsxs(Box, { sx: tabBoxStyle, children: [courseInstructions.map((item, index) => (_jsx("div", { children: _jsxs(Grid, { item: true, xs: 12, children: [_jsxs(Box, { sx: {
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }, mt: 1, children: [_jsxs(Typography, { variant: "h5", children: ["Section ", index + 1] }), _jsx(IconButton, { onClick: () => removeCourseInstruction(index), color: "error", children: _jsx("span", { className: "material-symbols-rounded", children: "remove" }) })] }, `header-box-instruction-${index}`), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsx(Grid, { item: true, lg: 12, xs: 12, children: courseCategoryTypeList && (_jsx(Controller, { name: `courseInstructions.${index}.courseInstructionCategory`, control: control, rules: {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseInstructions.courseInstructionCategory")),
                                                                                        },
                                                                                    }, render: ({ field: { onChange, value }, }) => (_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", filterSelectedOptions: true, options: courseInstructionCategoryTypeList, value: courseInstructionCategoryTypeList.find((option) => option.id === value?.toString()) || null, isOptionEqualToValue: (option, value) => option.id === value.id, onChange: (_, value) => {
                                                                                            onChange(value
                                                                                                ? Number(value?.id ?? undefined)
                                                                                                : null);
                                                                                        }, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("lessons.courseInstructions.courseInstructionCategory"), placeholder: translate("lessons.courseInstructions.courseInstructionCategory"), error: errors.courseInstructions &&
                                                                                                !!errors.courseInstructions[index]
                                                                                                    ?.courseInstructionCategory, helperText: errors.courseInstructions &&
                                                                                                errors.courseInstructions[index]
                                                                                                    ?.courseInstructionCategory
                                                                                                    ?.message })) })) })) }, `title-category-instruction-${index}`), _jsx(Grid, { item: true, lg: 6, xs: 12, children: _jsx(FormControl, { children: _jsx(RadioGroup, { "aria-labelledby": `demo-radio-buttons-group-label`, value: getValues(`courseInstructions.${index}.courseInstructionType`), onChange: ($event) => {
                                                                                            setValue(`courseInstructions.${index}.courseInstructionType`, Number($event.target.value));
                                                                                        }, children: _jsx(FormControlLabel, { value: CourseInstructionType.UploadAttachment, control: _jsx(Radio, {}), label: translate("cta.uploadAttachment") }) }) }, `courseInstructions.${index}.courseInstructionType`) }, `courseInstructionType-grid-${index}`), _jsx(Grid, { item: true, lg: 6, xs: 12, children: watch(`courseInstructions.${index}.courseInstructionType`) == CourseInstructionType.ExternalLink ? (_jsx(TextField, { id: `courseInstructions.${index}.link`, label: translate("lessons.courseItems.link"), variant: "outlined", size: "small", placeholder: translate("lessons.courseItems.link"), error: errors.courseInstructions &&
                                                                                        !!errors.courseInstructions[index]?.link, helperText: errors.courseInstructions &&
                                                                                        errors.courseInstructions[index]?.link
                                                                                            ?.message, fullWidth: true, ...register(`courseInstructions.${index}.link`, {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("lessons.courseItems.link")),
                                                                                        },
                                                                                        pattern: {
                                                                                            value: /^(http|https):\/\/[^ "]+$/,
                                                                                            message: translate("validations.invalidUrl"),
                                                                                        },
                                                                                    }) })) : (_jsxs(_Fragment, { children: [_jsx(Controller, { name: `courseInstructions.${index}.file`, control: control, rules: {
                                                                                                required: {
                                                                                                    value: getValues(`courseInstructions.${index}.fileName`) === undefined
                                                                                                        ? true
                                                                                                        : false,
                                                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("cta.uploadCourseInstructions")),
                                                                                                },
                                                                                                validate: (value) => validateInstructionFile(value, index),
                                                                                            }, render: ({ field }) => (_jsx(_Fragment, { children: _jsxs(Button, { component: "label", className: "Button", variant: "outlined", color: "primary", fullWidth: true, disabled: !hasEditPermission, endIcon: watch(`courseInstructions.${index}.fileName`) != undefined ||
                                                                                                        watch(`courseInstructions.${index}.file`) != undefined ? (_jsx(Edit, {})) : (_jsx(FileUpload, {})), children: [_jsx(Typography, { variant: "body1", sx: {
                                                                                                                whiteSpace: "normal",
                                                                                                                flex: "1 1 auto",
                                                                                                                wordBreak: "break-all",
                                                                                                            }, children: watch(`courseInstructions.${index}.fileName`) != undefined
                                                                                                                ? watch(`courseInstructions.${index}.fileName`)
                                                                                                                : watch(`courseInstructions.${index}.file`) == undefined
                                                                                                                    ? translate("cta.uploadFile")
                                                                                                                    : watch(`courseInstructions.${index}.file`).name }), _jsx("input", { id: index.toString(), type: "file", name: index.toString(), onChange: (e) => handleCourseInstructionFileChange(index, e), style: { display: "none" } })] }) })) }), errors.courseInstructions &&
                                                                                            errors.courseInstructions[index]
                                                                                                ?.file && (_jsx(WnValidation, { fieldError: errors.courseInstructions[index]
                                                                                                ?.file }))] })) }, `courseInstructions-value-grid-${index}`)] }, `item-grid-container-instruction-${index}`)] }, `main-grid-instruction-${index}`) }, `main-div-instruction-${index}`))), _jsx(Grid, { item: true, xs: 12, marginTop: fields.length > 0 ? 0 : 3, textAlign: "right", marginBottom: 2, children: _jsx(Button, { sx: { mt: 2 }, className: "Button", onClick: () => onAddNewInstructionSection(), variant: "outlined", disableElevation: true, color: "info", children: translate("cta.addNewSectionCourseItem") }) })] }) })] }), hasEditPermission && (_jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, marginTop: 5, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", disabled: errors.courseInstructions || errors.courseItems
                                                ? true
                                                : false, children: translate("cta.saveChanges") }) }))] }) })] }) }))] }));
};
export default EditLesson;
