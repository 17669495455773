import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "@emotion/react";
import "./style.scss";
import Header from "./header";
import Sidebar from "./menu";
import { Box, Container, Drawer, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useAppSelector } from "@application/store/useStore";
import { nlNL } from "@mui/x-data-grid";
import React from "react";
const customColors = {
    winter: {
        main: "#2D8AC5",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    inputBg: {
        main: "#F7F7F7",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#000",
    },
    customDark: {
        main: "#343233",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    white: {
        main: "#fff",
        light: "#fff",
        dark: "#fff",
        contrastText: "#000",
    },
};
const Layout = () => {
    const { isToggle } = useAppSelector((state) => state.toggle);
    const { currentUser } = useAppSelector((state) => state.auth);
    const theme = createTheme({
        palette: {
            // mode: 'dark',
            primary: {
                main: "#2D8AC5",
                light: "#42a5f5",
                dark: "#1565c0",
                contrastText: "#000",
            },
            error: {
                main: "#DA1539",
                light: "#42a5f5",
                dark: "#1565c0",
                contrastText: "#fff",
            },
            winter: {
                main: customColors.winter.main,
                light: customColors.winter.light,
                dark: customColors.winter.dark,
                contrastText: customColors.winter.contrastText,
            },
            inputBg: {
                main: customColors.inputBg.main,
                light: customColors.inputBg.light,
                dark: customColors.inputBg.dark,
                contrastText: customColors.inputBg.contrastText,
            },
            customDark: {
                main: customColors.customDark.main,
                light: customColors.customDark.light,
                dark: customColors.customDark.dark,
                contrastText: customColors.customDark.contrastText,
            },
            white: {
                main: customColors.white.main,
                light: customColors.white.light,
                dark: customColors.white.dark,
                contrastText: customColors.white.contrastText,
            },
            secondary: {
                main: "#ffba00",
            },
        },
        shape: {
            borderRadius: 20,
        },
        typography: {
            fontFamily: '"Work Sans", sans-serif',
            button: {
                fontSize: "1rem",
                fontWeight: "normal",
            },
        },
        // Override button hover color
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        whiteSpace: "nowrap",
                        "&:hover": {
                            backgroundColor: "winter.main",
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        border: "none",
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: "unset",
                    },
                },
            },
        },
    }, nlNL);
    const otherTheme = createTheme({
        palette: {
            // mode: 'dark',
            primary: {
                main: "#f68735",
                light: "#FFE4CF",
                dark: "#c15200",
                contrastText: "#000",
            },
            error: {
                main: "#DA1539",
                light: "#42a5f5",
                dark: "#1565c0",
                contrastText: "#fff",
            },
            winter: {
                main: customColors.winter.main,
                light: customColors.winter.light,
                dark: customColors.winter.dark,
                contrastText: customColors.winter.contrastText,
            },
            inputBg: {
                main: customColors.inputBg.main,
                light: customColors.inputBg.light,
                dark: customColors.inputBg.dark,
                contrastText: customColors.inputBg.contrastText,
            },
            customDark: {
                main: customColors.customDark.main,
                light: customColors.customDark.light,
                dark: customColors.customDark.dark,
                contrastText: customColors.customDark.contrastText,
            },
            white: {
                main: customColors.white.main,
                light: customColors.white.light,
                dark: customColors.white.dark,
                contrastText: customColors.white.contrastText,
            },
            secondary: {
                main: "#ffba00",
            },
        },
        shape: {
            borderRadius: 20,
        },
        typography: {
            fontFamily: '"Work Sans", sans-serif',
            button: {
                fontSize: "1rem",
                fontWeight: "normal",
            },
        },
        // Override button hover color
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        whiteSpace: "nowrap",
                        "&:hover": {
                            backgroundColor: "winter.main",
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        border: "none",
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: "unset",
                    },
                },
            },
        },
    });
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:912px)");
    return (_jsxs(ThemeProvider, { theme: currentUser?.role.toLowerCase() == "superadmin" ||
            currentUser?.role.toLowerCase() == "admin"
            ? theme
            : otherTheme, children: [_jsx(CssBaseline, {}), _jsxs("div", { className: "MainWrapper Admin", children: [_jsx(Header, { handleDrawer: () => setOpen(true) }), !isSmall && _jsx(Sidebar, { isDrawer: false }), _jsx("main", { className: isToggle || isSmall ? "full" : "", children: _jsx(Container, { maxWidth: "xl", children: _jsx(Outlet, {}) }) }), _jsxs(Drawer, { anchor: "left", open: open, onClose: toggleDrawer(false), children: [_jsx(Box, { width: 330 }), _jsx("div", { className: "MainWrapper Admin", children: _jsx("div", { style: {
                                        width: "300px",
                                        zIndex: 5000,
                                        position: "absolute",
                                        top: "0px",
                                        height: 'auto',
                                        left: "0px", paddingBottom: '48px'
                                    }, children: _jsx(Sidebar, { isDrawer: true, handleClose: toggleDrawer(false) }) }) })] })] })] }));
};
export default Layout;
