import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, Typography, useMediaQuery, } from "@mui/material";
import { Add, FilterAltOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { enableDisableVoucherAsync, getVouchersAsync, } from "@application/features/voucher/voucherThunk";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import "./style.scss";
import { Module } from "@application/common/enums/modules";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { resetFilter } from "@application/features/common/filterResetSlice";
import { setVouchersFilterModel } from "@application/features/voucher/voucherSlice";
const Vouchers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { vouchers, vouchersFilterModel } = useAppSelector((state) => state.voucher);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Vouchers]);
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getVouchersAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(resetFilter("vouchers"));
    }, [dispatch]);
    const onEnableDisable = ($event, id, teacherId) => {
        dispatch(enableDisableVoucherAsync({
            id: id,
            status: $event,
            teacherId: teacherId,
        })).then((res) => {
            if (res.payload === true) {
                dispatch(getVouchersAsync());
            }
        });
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "voucherCode",
            headerName: translate("vouchers.voucherCode").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "school",
            headerName: translate("schools.school").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.school.label}`,
        },
        {
            field: "board",
            headerName: translate("boards.board").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.board.label}`,
        },
        {
            field: "applicationUser",
            headerName: translate("teachers&Supervisors.teacher").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.applicationUser.label}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            filterable: false,
            hideable: false,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, disable: !params.row.board.isActive ||
                    !params.row.school.isActive ||
                    !params.row.applicationUser.isActive, onChange: ($event) => onEnableDisable($event, params.row.id, params.row.applicationUser.id) }, params.row.id)),
        },
    ];
    const onFilterModelChange = (model) => dispatch(setVouchersFilterModel(model));
    const onClearFilterModel = () => dispatch(setVouchersFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("vouchers.vouchers") }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })), hasCreatePermission &&
                                (isxs ? (_jsx(IconButton, { size: "medium", onClick: () => navigate("add"), children: _jsx(Add, { color: "primary", fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") })))] })] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(Box, { sx: { width: "100%" }, children: _jsx(DataGrid, { autoHeight: true, componentsProps: { filterPanel: { sx: { maxWidth: "85vw" } } }, rows: vouchers ?? [], columns: columns, filterModel: vouchersFilterModel, onFilterModelChange: onFilterModelChange, hideFooterPagination: !vouchers?.length, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            isActive: hasEditPermission ?? true,
                        } }, "teacher-grid") }) })] }));
};
export default Vouchers;
