import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import "./style.scss";
import { Add, FilterAltOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import WNSwitch from "@ui/components/wn-switch";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { updateSchoolStatusAsync } from "@application/features/school/schoolThunk";
import { DataGrid, } from "@mui/x-data-grid";
import { AppConsts } from "@application/common/constants/app-consts";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { getSchoolsByBoardIdAsync } from "@application/features/board/boardThunk";
import GoBack from "@ui/components/wn-go-back";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { translate } from "@application/common/helpers/translate";
import { resetFilter } from "@application/features/common/filterResetSlice";
import { setBoardSchoolsFilterModel } from "@application/features/board/boardSlice";
function SchoolList() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { boardId } = useParams();
    const { schoolList, boardSchoolFilterModel, status } = useAppSelector((state) => state.board);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Schools]);
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1200px)");
    const columns = [
        {
            field: "name",
            headerName: translate("schools.schoolName").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            hideable: false,
        },
        {
            field: "board",
            headerName: translate("boards.board").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => params.row.board.label,
        },
        {
            field: "teachersCount",
            headerName: translate("teachers&Supervisors.teachers").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "agreementsCount",
            headerName: translate("agreements.agreements").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 220 : undefined,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "lastAgreementDate",
            headerName: translate("schools.lastAgreement").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 260 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${params.row.lastAgreementDate !== null
                ? formateDate(params.row.lastAgreementDate, DateFormatConstants.DMY)
                : ""}`,
        },
        {
            field: "isActive",
            headerName: translate("common.active").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 140 : undefined,
            filterable: false,
            hideable: false,
            hideSortIcons: false,
            cellClassName: "tableSwitchAlignCenter",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNSwitch, { checked: params.row.isActive, disable: !params.row.board.isActive, onChange: ($event) => onSchoolStatusUpdate({
                    id: params.row.id,
                    status: $event,
                }) }, params.row.id)),
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            filterable: false,
            hideable: false,
            hideSortIcons: false,
            renderCell: (params) => (_jsx(Button, { size: "small", className: "Button", sx: { fontSize: "14px" }, onClick: () => navigate(`/admin/schools/${params.row.id}`), children: translate("cta.details") })),
        },
    ];
    useEffect(() => {
        if (boardId) {
            dispatch(getSchoolsByBoardIdAsync(boardId));
        }
    }, [dispatch, boardId]);
    useEffect(() => {
        dispatch(resetFilter("boards"));
    }, [dispatch]);
    const onSchoolStatusUpdate = (updateSchoolStatusDto) => {
        dispatch(updateSchoolStatusAsync(updateSchoolStatusDto)).then((res) => {
            if (res.payload.id != null) {
                if (boardId) {
                    dispatch(getSchoolsByBoardIdAsync(boardId));
                }
            }
        });
    };
    const onFilterModelChange = (model) => dispatch(setBoardSchoolsFilterModel(model));
    const onClearFilterModel = () => dispatch(setBoardSchoolsFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("schools.schools") }) }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })), hasCreatePermission &&
                                (isxs ? (_jsx(IconButton, { size: "medium", onClick: () => navigate("/admin/schools/add"), children: _jsx(Add, { color: "primary", fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/schools/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") })))] })] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx(DataGrid, { componentsProps: { filterPanel: { sx: { maxWidth: "85vw" } } }, autoHeight: true, rows: schoolList ?? [], columns: columns, 
                    //loading={status === APIStatus.PENDING}
                    filterModel: boardSchoolFilterModel, onFilterModelChange: onFilterModelChange, initialState: {
                        pagination: {
                            paginationModel: {
                                pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                            },
                        },
                    }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: schoolList === undefined || schoolList?.length <= 0, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                        isActive: hasEditPermission ?? true,
                    } }, "school-grid") })] }));
}
export default SchoolList;
