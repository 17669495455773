import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, IconButton, Typography, useMediaQuery, } from "@mui/material";
import "./style.scss";
import { Add, FilterAltOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DataGrid, } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { useEffect } from "react";
import { AppConsts } from "@application/common/constants/app-consts";
import { formateDate } from "@application/common/helpers/date-time-helper";
import { DateFormatConstants } from "@application/common/constants/date-format";
import WnNoRecordFound from "@ui/components/wn-no-record";
import { getAgreementsAsync, getDownloadAndViewAgreementAsync, } from "@application/features/agreements/agreementThunk";
import { AgreementStatusResultMap } from "@application/common/enums/agreement-status";
import WNLongMenu from "@ui/components/wn-long-menu";
import { validateModulePermission } from "@application/common/helpers/role-claims-helper";
import { Module } from "@application/common/enums/modules";
import { PermissionType } from "@application/common/enums/permission-type";
import { translate } from "@application/common/helpers/translate";
import { resetFilter } from "@application/features/common/filterResetSlice";
import { setAgreementFilterModel } from "@application/features/agreements/agreementSlice";
const Agreements = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { agreements, agreementsFilterModel } = useAppSelector((state) => state.agreement);
    const { loggedInUserRolePermission } = useAppSelector((state) => state.rolePermission);
    const modulePermissions = loggedInUserRolePermission &&
        validateModulePermission(loggedInUserRolePermission, Module[Module.Agreements]);
    const hasViewPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.viewPermission]]
        : false;
    const hasCreatePermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.createPermission]]
        : false;
    const hasEditPermission = modulePermissions
        ? !!modulePermissions[PermissionType[PermissionType.editPermission]]
        : false;
    useEffect(() => {
        dispatch(getAgreementsAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(resetFilter("agreements"));
    }, [dispatch]);
    const downloadAgreement = (file) => {
        if (file) {
            const fileName = file.split("/")[1];
            if (fileName) {
                dispatch(getDownloadAndViewAgreementAsync(fileName)).then((res) => {
                    if (res.payload !== null &&
                        res.payload !== undefined &&
                        res.payload !== "") {
                        const base64Data = `data:image/${fileName.split(".")[1]};base64,${res.payload}`;
                        var a = document.createElement("a");
                        a.href = base64Data;
                        a.download = `Agreement.${fileName.split(".")[1]}`;
                        a.target = "_blank";
                        a.click();
                    }
                });
            }
            else {
                console.log("no file name");
            }
        }
        else {
            console.log("no file");
        }
    };
    const isSmall = useMediaQuery("(min-width:100px) and (max-width:1280px)");
    const columns = [
        {
            field: "school",
            headerName: translate("schools.school").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.board.name.toLowerCase() == "uncategorized"
                ? params.row.school.name
                : "All"}`,
        },
        {
            field: "board",
            headerName: translate("boards.board").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            valueGetter: (params) => `${params.row.board.name}`,
        },
        {
            field: "status",
            headerName: translate("agreements.status").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            hideable: false,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${AgreementStatusResultMap.get(params.row.status)}`,
        },
        {
            field: "startDate",
            headerName: translate("agreements.startDate").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${formateDate(params.row.startDate, DateFormatConstants.DMY)}`,
        },
        {
            field: "endDate",
            headerName: translate("agreements.endDate").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${formateDate(params.row.endDate, DateFormatConstants.DMY)}`,
        },
        {
            field: "submissionDate",
            headerName: translate("agreements.submissionDate").toUpperCase(),
            sortable: true,
            flex: 1,
            minWidth: isSmall ? 200 : undefined,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => `${formateDate(params.row.submissionDate, DateFormatConstants.DMY)}`,
        },
        {
            field: "",
            headerName: translate("common.actions").toUpperCase(),
            sortable: false,
            flex: 1,
            minWidth: isSmall ? 180 : undefined,
            filterable: false,
            hideable: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (_jsx(WNLongMenu, { options: options, onClick: (option) => onLongMenuClick(option, params.row) }, params.row.id)),
        },
    ];
    const onLongMenuClick = (selectedOption, row) => {
        switch (selectedOption) {
            case "Details":
                navigate(`${row.id}`);
                break;
            case "Download":
                downloadAgreement(row.agreementAttachment);
                break;
            default:
                break;
        }
    };
    let options = [
        ...(hasEditPermission || hasViewPermission
            ? [
                {
                    key: "Details",
                    label: translate("cta.details"),
                    route: "Details",
                    isActive: true,
                },
            ]
            : []),
        ...(hasViewPermission
            ? [
                {
                    key: "Download",
                    label: translate("cta.download"),
                    route: "Download",
                    isActive: true,
                },
            ]
            : []),
    ];
    const onFilterModelChange = (model) => dispatch(setAgreementFilterModel(model));
    const onClearFilterModel = () => dispatch(setAgreementFilterModel({ items: [] }));
    const isxs = useMediaQuery("(min-width:100px) and (max-width:750px)");
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: { fontWeight: "bold" }, children: translate("agreements.agreements") }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [isxs ? (_jsx(IconButton, { size: "medium", onClick: onClearFilterModel, children: _jsx(FilterAltOff, { fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "outlined", disableElevation: true, color: "primary", onClick: onClearFilterModel, startIcon: _jsx(FilterAltOff, {}), children: "Clear Filter" })), hasCreatePermission &&
                                (isxs ? (_jsx(IconButton, { size: "medium", onClick: () => navigate("/admin/agreements/add"), children: _jsx(Add, { color: "primary", fontSize: "medium" }) })) : (_jsx(Button, { className: "Button", variant: "contained", disableElevation: true, color: "primary", onClick: () => navigate("/admin/agreements/add"), startIcon: _jsx(Add, {}), children: translate("cta.createNew") })))] })] }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsx("div", { style: { width: "100%" }, children: _jsx(DataGrid, { componentsProps: { filterPanel: { sx: { maxWidth: "85vw" } } }, autoHeight: true, rows: agreements ?? [], columns: columns, filterModel: agreementsFilterModel, onFilterModelChange: onFilterModelChange, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
                                },
                            },
                        }, pageSizeOptions: [AppConsts.GRID_DEFAULT_PAGE_SIZE], disableRowSelectionOnClick: true, hideFooterPagination: !agreements.length, slots: { noRowsOverlay: WnNoRecordFound }, sx: { "--DataGrid-overlayHeight": "300px" }, columnVisibilityModel: {
                            "": (hasEditPermission || hasViewPermission) ?? true,
                        } }, "theme-grid") }) })] }));
};
export default Agreements;
