import { APIStatus } from "@application/common/enums/api-status";
import { createSlice } from "@reduxjs/toolkit";
import { addSchoolAsync, deleteSchoolAsync, getEffectedSchoolAsync, getSchoolAsync, getSchoolsAsync, getTeachersBySchoolIdAsync, updateSchoolAsync, updateSchoolStatusAsync, } from "./schoolThunk";
const initialState = {
    status: APIStatus.IDLE,
    schoolDropdownList: [],
    schoolFilterModel: {
        items: []
    },
    schoolTeachersFilterModel: {
        items: []
    },
    deleteSchoolResult: false,
    isDeleteOpen: false
};
export const schoolSlice = createSlice({
    name: "school",
    initialState,
    reducers: {
        resetSchoolTeachers(state) {
            state.schoolTeachers = [];
        },
        setSchoolFilterModel(state, action) {
            state.schoolFilterModel = action.payload || {
                items: []
            };
        },
        setSchoolTeachersFilterModel(state, action) {
            state.schoolTeachersFilterModel = action.payload || {
                items: []
            };
        },
        setDeleteOpenDialog(state, action) {
            state.isDeleteOpen = action.payload;
        },
        setEffectedSchool(state, action) {
            state.effectedRows = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSchoolsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.schools = action.payload;
        })
            .addCase(getSchoolsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSchoolAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSchoolAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.school = action.payload;
        })
            .addCase(getSchoolAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateSchoolStatusAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateSchoolStatusAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.school = action.payload;
        })
            .addCase(updateSchoolStatusAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addSchoolAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addSchoolAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addSchoolAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateSchoolAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateSchoolAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateSchoolAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeachersBySchoolIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeachersBySchoolIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.schoolTeachers = action.payload;
        })
            .addCase(getTeachersBySchoolIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(deleteSchoolAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(deleteSchoolAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.deleteSchoolResult = action.payload;
        })
            .addCase(deleteSchoolAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getEffectedSchoolAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getEffectedSchoolAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.effectedRows = action.payload;
        })
            .addCase(getEffectedSchoolAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    },
});
export const { resetSchoolTeachers, setSchoolFilterModel, setSchoolTeachersFilterModel, setDeleteOpenDialog, setEffectedSchool } = schoolSlice.actions;
export default schoolSlice.reducer;
