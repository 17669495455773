import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { addTeacherAsync, enableDisableTeacherAsync, getTeacherByIdAsync, getTeachersAsync, updateTeacherAsync } from './teacherThunk';
const initialState = {
    status: APIStatus.IDLE,
    teacherFilterModel: {
        items: []
    }
};
export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        setTeacherFilterModel(state, action) {
            state.teacherFilterModel = action.payload || {
                items: []
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeachersAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeachersAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teachers = action.payload;
        })
            .addCase(getTeachersAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getTeacherByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getTeacherByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.teacher = action.payload;
        })
            .addCase(getTeacherByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(addTeacherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(addTeacherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(addTeacherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateTeacherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateTeacherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateTeacherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableDisableTeacherAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableDisableTeacherAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableDisableTeacherAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setTeacherFilterModel } = teacherSlice.actions;
export default teacherSlice.reducer;
