import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { addBoardsAsync } from "@application/features/board/boardThunk";
import { getCountryDropDownAsync } from "@application/features/country/countryThunk";
import { useAppDispatch, useAppSelector } from "@application/store/useStore";
import { Autocomplete, Box, Button, Divider, Grid, TextField, Typography, } from "@mui/material";
import GoBack from "@ui/components/wn-go-back";
import WnValidation from "@ui/components/wn-validation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { translate } from "@application/common/helpers/translate";
import { LicenseInvoiceType, LicenseInvoiceTypeStatusMap, } from "@application/common/enums/license-invoice-type";
const AddBoards = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const invoiceToDropdownList = [
        {
            id: Number(LicenseInvoiceType.Management).toString(),
            label: LicenseInvoiceTypeStatusMap.get(LicenseInvoiceType.Management),
        },
        {
            id: Number(LicenseInvoiceType.School).toString(),
            label: LicenseInvoiceTypeStatusMap.get(LicenseInvoiceType.School),
        },
    ];
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedLicenseToOption, setSelectedLicenseToOption] = useState(null);
    const [selectedAcademyToOption, setSelectedAcademyToOption] = useState(null);
    const { countryDropdownList } = useAppSelector((state) => state.country);
    useEffect(() => {
        dispatch(getCountryDropDownAsync());
    }, [dispatch]);
    const { register, setError, setValue, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            contactPerson: "",
            postalCode: "",
            street: "",
            houseNumber: "",
            additionalInformation: "",
            city: "",
            countryId: "",
            address: "",
            invoiceSetting: {
                billingEmail: "",
                licensePurchaseNumber: "",
                licenseInvoiceTo: -1,
                purchaseNumberAcademy: "",
                academyInvoiceTo: -1,
            },
        },
    });
    const onSubmit = (credentials) => {
        credentials.countryId = selectedOption?.id ?? "";
        credentials.invoiceSetting.licenseInvoiceTo = Number(selectedLicenseToOption?.id ?? -1);
        credentials.invoiceSetting.academyInvoiceTo = Number(selectedAcademyToOption?.id ?? -1);
        dispatch(addBoardsAsync(credentials)).then((res) => {
            if (res.payload === true) {
                navigate("/admin/boards");
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { display: "flex" }, justifyContent: "space-between", alignItems: "center", children: _jsx(Box, { sx: { display: "flex" }, gap: "4", alignItems: "center", children: _jsx(GoBack, { title: translate("cta.newModule").replace("{module}", translate("boards.board")) }) }) }), _jsx(Box, { sx: {
                    marginTop: "1rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                }, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), id: "board-form", noValidate: true, children: [_jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("account.generalInfo") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(TextField, { id: "name", label: translate("account.name"), variant: "outlined", size: "small", placeholder: translate("account.name"), error: !!errors.name, fullWidth: true, ...register("name", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.name")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.name })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(TextField, { id: "email", label: translate("account.email"), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), type: "email", error: !!errors.email, fullWidth: true, ...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.email")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                        pattern: {
                                                            value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translate("validations.invalidEmailAddress"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.email })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(TextField, { id: "phone", label: translate("account.phoneNumber"), variant: "outlined", size: "small", placeholder: translate("account.phoneNumber"), fullWidth: true, error: !!errors.phone, ...register("phone", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.phoneNumber")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.phone })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, children: [_jsx(TextField, { id: "contact_person", label: translate("account.contactPerson"), variant: "outlined", size: "small", placeholder: translate("account.contactPerson"), type: "text", fullWidth: true, error: !!errors.contactPerson, ...register("contactPerson", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.contactPerson")),
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate("validations.maximumCharactersAllowed").replace("{count}", "100"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.contactPerson })] })] })] }), _jsx(Box, { marginTop: 3, marginBottom: 3, children: _jsx(Divider, {}) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("account.addressInfo") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "street", label: translate("account.street"), variant: "outlined", size: "small", placeholder: translate("account.street"), error: !!errors.street, fullWidth: true, ...register("street", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.street")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.street })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "houseNumber", label: translate("account.houseNumber"), variant: "outlined", size: "small", placeholder: translate("account.houseNumber"), type: "text", error: !!errors.houseNumber, fullWidth: true, ...register("houseNumber", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.houseNumber")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.houseNumber })] }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(TextField, { id: "additionalInformation", label: translate("account.additionalInformation"), variant: "outlined", size: "small", placeholder: translate("account.additionalInformation"), fullWidth: true, ...register("additionalInformation", {}) }) }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "postal_code", label: translate("account.postalCode"), variant: "outlined", size: "small", placeholder: translate("account.postalCode"), type: "tel", fullWidth: true, error: !!errors.postalCode, ...register("postalCode", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.postalCode")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.postalCode })] }), _jsxs(Grid, { item: true, xs: 12, sm: 6, md: 4, children: [_jsx(TextField, { id: "city", label: translate("account.city"), variant: "outlined", size: "small", placeholder: translate("account.city"), fullWidth: true, error: !!errors.city, ...register("city", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("account.city")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.city })] }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: countryDropdownList && (_jsxs(_Fragment, { children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedOption, onChange: (event, newValue) => {
                                                            setValue("countryId", newValue?.id ?? "");
                                                            setSelectedOption(newValue);
                                                            if (newValue !== null && newValue !== undefined) {
                                                                setError("countryId", {
                                                                    type: "required",
                                                                    message: undefined,
                                                                });
                                                            }
                                                        }, options: countryDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("account.country"), variant: "outlined", ...register("countryId", {
                                                                required: {
                                                                    value: true,
                                                                    message: translate("validations.fieldIsRequired").replace("{field}", translate("account.country")),
                                                                },
                                                            }), error: errors.countryId && selectedOption === null
                                                                ? true
                                                                : false })) }), _jsx(WnValidation, { fieldError: errors.countryId })] })) })] })] }), _jsx(Box, { marginTop: 3, marginBottom: 3, children: _jsx(Divider, {}) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h5", fontWeight: "500", marginBottom: 2, children: translate("invoiceSettings.invoiceSettings") }), _jsxs(Grid, { container: true, spacing: 2.5, children: [_jsxs(Grid, { item: true, lg: 6, md: 6, xs: 12, sm: 6, children: [_jsx(TextField, { id: "billing_email", label: translate("invoiceSettings.billingEmail"), variant: "outlined", size: "small", placeholder: translate("account.emailPlaceholder"), fullWidth: true, error: !!errors.invoiceSetting?.billingEmail, ...register("invoiceSetting.billingEmail", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.billingEmail")),
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translate("validations.invalidEmailAddress"),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.billingEmail })] }), _jsx(Grid, { item: true, lg: 6, md: 6, xs: 12, display: { xs: "none", sm: "none" } }), _jsxs(Grid, { item: true, lg: 6, md: 6, xs: 12, sm: 6, children: [_jsx(TextField, { id: "license_purchase_no", label: translate("invoiceSettings.licensePurchaseNumber"), variant: "outlined", size: "small", placeholder: translate("invoiceSettings.licensePurchaseNumber"), fullWidth: true, error: !!errors.invoiceSetting?.licensePurchaseNumber, ...register("invoiceSetting.licensePurchaseNumber", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.licensePurchaseNumber")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.licensePurchaseNumber })] }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedLicenseToOption, onChange: (event, newValue) => {
                                                        setValue("invoiceSetting.licenseInvoiceTo", Number(newValue?.id ?? -1));
                                                        setSelectedLicenseToOption(newValue);
                                                        if (newValue !== null && newValue !== undefined) {
                                                            setError("invoiceSetting.licenseInvoiceTo", {
                                                                type: "required",
                                                                message: undefined,
                                                            });
                                                        }
                                                    }, options: invoiceToDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("invoiceSettings.licenseInvoiceTo"), variant: "outlined", ...register("invoiceSetting.licenseInvoiceTo", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.licenseInvoiceTo")),
                                                            },
                                                        }), error: errors.invoiceSetting?.licenseInvoiceTo &&
                                                            selectedLicenseToOption === null
                                                            ? true
                                                            : false })) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.licenseInvoiceTo })] }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [_jsx(TextField, { id: "purchase_number_academy", label: translate("invoiceSettings.purchaseNumberAcademy"), variant: "outlined", size: "small", placeholder: translate("invoiceSettings.purchaseNumberAcademy"), fullWidth: true, error: !!errors.invoiceSetting?.purchaseNumberAcademy, ...register("invoiceSetting.purchaseNumberAcademy", {
                                                        required: {
                                                            value: true,
                                                            message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.purchaseNumberAcademy")),
                                                        },
                                                    }) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.purchaseNumberAcademy })] }), _jsxs(Grid, { item: true, lg: 6, md: 6, sm: 6, xs: 12, children: [_jsx(Autocomplete, { noOptionsText: translate("validations.noOptionsText"), size: "small", value: selectedAcademyToOption, onChange: (event, newValue) => {
                                                        setValue("invoiceSetting.academyInvoiceTo", Number(newValue?.id ?? -1));
                                                        setSelectedAcademyToOption(newValue);
                                                        if (newValue !== null && newValue !== undefined) {
                                                            setError("invoiceSetting.academyInvoiceTo", {
                                                                type: "required",
                                                                message: undefined,
                                                            });
                                                        }
                                                    }, options: invoiceToDropdownList, getOptionLabel: (option) => option.label, renderInput: (params) => (_jsx(TextField, { ...params, InputLabelProps: { children: "" }, label: translate("invoiceSettings.academyInvoiceTo"), variant: "outlined", ...register("invoiceSetting.academyInvoiceTo", {
                                                            required: {
                                                                value: true,
                                                                message: translate("validations.fieldIsRequired").replace("{field}", translate("invoiceSettings.academyInvoiceTo")),
                                                            },
                                                        }), error: errors.invoiceSetting?.academyInvoiceTo &&
                                                            selectedAcademyToOption === null
                                                            ? true
                                                            : false })) }), _jsx(WnValidation, { fieldError: errors.invoiceSetting?.academyInvoiceTo })] })] })] }), _jsx(Box, { marginTop: 3, children: _jsx(Grid, { container: true, spacing: 2.5, children: _jsx(Grid, { item: true, xs: 12, textAlign: "right", marginBottom: 2, children: _jsx(Button, { className: "Button", type: "submit", variant: "contained", disableElevation: true, color: "primary", children: translate("cta.submit") }) }) }) })] }) })] }));
};
export default AddBoards;
